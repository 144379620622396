import { FC, ReactNode, useEffect, useRef, useState } from "react";
import UserPopup from "./UserPopup";

interface UserPopupWrapperProps {
    userId: number;
    children: ReactNode[] | ReactNode;
}

const UserPopupWrapper: FC<UserPopupWrapperProps> = ({ userId, children }) => {
    const RIGHT_BORDER_MARGIN = 20;
    const LEFT_BORDER_MARGIN = 20;

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [popupLoaded, setPopupLoaded] = useState<boolean>(false);

    const elemRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!showPopup) return;
        const popup = popupRef.current;
        const elem = elemRef.current;
        if (!elem) return;
        if (!popup) return;
        const elemRect = elem.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        // В зависимости от того в какой половине экрана находится элемент, определяем положение попапа
        if (elemRect.top < viewportHeight / 2) {
            popup.style.top = `${elemRect.bottom + window.scrollY + 10}px `;
        } else {
            popup.style.top = `${
                elemRect.top - popup.offsetHeight + window.scrollY - 10
            }px`;
        }

        popup.style.position = "fixed";
        popup.style.zIndex = "100000";

        // Центрируем попап по горизонтали относительно аватарки
        const baseLeft =
            elemRect.left - (elemRect.width * 1) / 2 + window.scrollX;
        const left = Math.min(
            Math.max(LEFT_BORDER_MARGIN, baseLeft),
            viewportWidth - popup.offsetWidth - RIGHT_BORDER_MARGIN
        );
        popup.style.left = `${left}px`;
    }, [showPopup, popupLoaded]);

    return (
        <div>
            <div
                ref={elemRef}
                onClick={(e) => {
                    if (userId === undefined) return;
                    setShowPopup(true);
                }}
                style={{ width: "fit-content", height: "fit-content" }}
            >
                {children}
            </div>
            {userId && showPopup ? (
                <div ref={popupRef}>
                    <UserPopup
                        userId={userId}
                        onOverlayClick={() => {
                            setShowPopup(false);
                            setPopupLoaded(false);
                        }}
                        onLoaded={() => setPopupLoaded(true)}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default UserPopupWrapper;
