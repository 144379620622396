import React, {FC, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Route, useLocation, useNavigate} from "react-router-dom";
import { Context } from '../..';

//to commit 
const LoginToken: FC = () => {
    const { store } = useContext(Context);
    const loadOriginPage = () =>{
        (window as any).YaSendSuggestToken(
            store.getExtUrl(), 
            {
               flag: true
            }
         )
         
    }

    useEffect(() => {
        (async () => {
            try {
                (() => {
                    const script = document.createElement("script");
                    script.src = "https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js";
                    script.async = true;
                    script.onload  = () => loadOriginPage();
                  
                    document.body.appendChild(script);
                })()
            } catch (error) {
                console.log(error)
            }
        })();
    }, []);
 
    return (
        <div>
            
        </div>
    )
}

export default observer(LoginToken);