import {FC, useContext, useEffect, useState} from 'react';
import "./TaskInformationForm.css"
import EditIcon from '../../../assets/edit.svg'
import EditIconGray from '../../../assets/editButtonGray.svg'
import {useParams} from 'react-router-dom';
import {Context} from "../../../index";
import TaskPriority from "../../Shared/TaskPriority";
import TaskType from "../../Shared/TaskType";
import {ITag, ITaskFormDisplay} from "../../../models/ITaskFormDisplay";
import {TaskContext} from '../../../pages/Task/Task';
import TaskAddEditForm from "../TaskAddEditForm/TaskAddEditForm";
import TasksTagDisplay from "../../Shared/TagSelectorAndDisplay/TasksTagDisplay";
import {IHasPermission} from "../../../models/IChekRole";
import {AppContext} from "../../../App";


interface ITaskPage {
    projectId?: number;
    userAccess: IHasPermission[];
}


const TaskInformationForm: FC<ITaskPage> = ({projectId, userAccess}) => {
    const {showToast} = useContext(AppContext);

    const {taskInformation, loadTaskInfo, getAllTaskPerson} = useContext(TaskContext);
    const {id} = useParams();
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const {store} = useContext(Context);
    const [tags, setTags] = useState<ITag[]>([]);

    // Ограничение прав
    const [editTaskInfo, setEditTaskInfo] = useState(false);

    useEffect(() => {
        console.log("userAccess", userAccess)
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") { //Информация по задаче
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditTaskInfo(true);
                    }
                })
            }
            if (store.user.email == "admin@admin.adm") {
                setEditTaskInfo(true);
            }
        })

    }, [userAccess]);

    function updateTaskPriority(index: number) {
        if (editTaskInfo) {
            (async () => {
                try {
                    let res = await store.updateTaskPriority(Number(id), index);
                    await loadTaskInfo();
                } catch (error) {
                    console.log(error)
                }
            })();
        } else {
            showToast("У вас нет прав для редактирования задачи!");
        }
    }

    function updateTaskType(index: number) {
        if (editTaskInfo) {
            (async () => {
                try {
                    let res = await store.updateTaskType(Number(id), index);
                    await loadTaskInfo();
                } catch (error) {
                    console.log(error)
                }
            })();
        } else {
            showToast("У вас нет прав для редактирования задачи!");
        }
    }

    function onEditTaskClick(e: any) {
        if (editTaskInfo) {
            (async () => {
                e.stopPropagation();
                store.getTaskForm(Number(id)).then((res) => {
                    if (!res?.id) {
                        return;
                    }
                    setShowEditForm(!showEditForm);
                });
                await loadTaskInfo()
            })();
        } else {
            showToast("У вас нет прав для редактирования задачи!");
        }
    }


    const taskPriorityDisplay = (rowData: any, index: number) => {
        const style = JSON.parse(rowData?.color);
        return (
            <button className="button__taskPriorityDisplay--chek" onClick={() => updateTaskPriority(index)}>
                <TaskPriority name={rowData.name} color={style?.color} icon={style.icon}/>
            </button>
        );
    };

    const taskTypeDisplay = (rowData: any, index: number) => {
        const style = JSON.parse(rowData?.color);
        return (
            <button className="button__taskPriorityDisplay--chek" onClick={() => updateTaskType(index)}>
                <TaskType name={rowData.name} color={style?.color} icon={style?.icon}/>
            </button>
        );
    };

    function onAddTags(_tags: ITag[]) {
        let newTags = tags?.filter((xx) => xx.id < 0);
        setTags([...newTags, ..._tags].filter((value, index, self) => self.indexOf(value) === index));
        (async () => {
            await store.addTagsInTasks(tags, taskInformation?.id!)
        })();
    }


    function removeTag(tag: ITag) {
        setTags([...tags?.filter((xx) => xx.id !== tag.id)]);
    }

    function onEditFormClose() {
        setShowEditForm(false);
        (async () => {
            await loadTaskInfo();
            await getAllTaskPerson();
        })();
    }

    useEffect(() => {
        (async () => {
            try {
                await loadTaskInfo();
                console.log(taskInformation?.tags!)
                setTags(taskInformation?.tags!);
            } catch (error) {
            }
        })();
    }, [id]);

    useEffect(() => {
        (async () => {
            try {
                await loadTaskInfo();
                store.setCurrentProjectId(projectId ?? 1);
            } catch (error) {
            }
        })();
    }, [store.isTaskUpdate]);

    return (
        <div className="widget__task--information">
            {showEditForm ? (
                <div className="full-screen-overlay">
                    <div className="popup-form-background">
                        <TaskAddEditForm mode={"edit"} taskId={Number(id)} closeFunc={onEditFormClose}
                                         projectId={projectId}
                                         permission = {editTaskInfo}
                        />
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="widget__task--information__head">
                <div className="widget__task--information__name">
                    <p className="widget__task--information__p--style">{taskInformation?.shortDescription}</p>
                </div>
                <div className="widget__task--information__edit" onClick={onEditTaskClick}>
                    <div className="widget__task--information__edit--block">
                        <img src={editTaskInfo ? EditIcon : EditIconGray}/>
                    </div>
                </div>
            </div>
            <div className="widget__task--information__desk--block">
                <p className="m-0">{taskInformation?.description}</p>
            </div>
            <div className="widget__task--information__priority--type">
                <div className="widget__task--information__priority--type--block">
                    <div className="widget__task--information__priority--type--block__head">
                        <p className="widget__task--information__p--style">Приоритет</p>
                    </div>
                    <div className="widget__task--information__priority--type--block__list">
                        {taskInformation?.priority?.map((priority, index) => (
                            <div key={index + 1}
                                 style={priority.curent ? {} : {opacity: 0.3}}
                                 className="widget__task--information__priority--type--block__list--margin">
                                {taskPriorityDisplay(priority, index + 1)}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="widget__task--information__priority--type--block">
                    <div className="widget__task--information__priority--type--block__head">
                        <p className="widget__task--information__p--style">Тип задачи</p>
                    </div>
                    <div className="widget__task--information__priority--type--block__list">
                        {taskInformation?.type?.map((type, index) => (
                            <div key={index + 1}
                                 style={type.curent ? {} : {opacity: 0.3}}
                                 className="widget__task--information__priority--type--block__list--margin">
                                {taskTypeDisplay(type, index + 1)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="widget__task--information__tegs">
                <div>
                    <p className="widget__task--information__p--style">Теги</p>
                </div>
                <div className="widget__task--information__tegs--view">
                    {taskInformation?.tags && (
                        <div className="widget__task--information__tegs--view__task--tag">
                            <TasksTagDisplay projectId={projectId ?? store.currentProjectId}
                                             taskId={taskInformation?.id}
                                             tags={taskInformation?.tags}
                                             addTagsFunc={onAddTags}
                                             removeTag={removeTag}
                                             permission={editTaskInfo}

                            />
                        </div>
                    )
                    }
                </div>
            </div>
        </div>
    );
};

export default TaskInformationForm;