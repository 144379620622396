import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import "../../styles/filesComponent.pc.css";
import "./HistoryProject.css";
import "../../../globalstyles/dataTableStyles.css";
import OpenBlock from "../../../assets/openBlock.svg";
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";
import { Context } from "../../../index";
import { IHasPermission } from "../../../models/IChekRole";
import {
    IGetProjectHistoryFilters,
    IProjectHistoryDisplay,
} from "../../../models/ProjectModels";
import { useParams } from "react-router-dom";
import HistoryTable from "../../Shared/HistoryTable/HistoryTable";
import Search from "../../Shared/Search/Search";
import CancelButton from "../../buttons/CancelButton/CancelButton";
import { IFilter, IFilterItem } from "../../../models/response/IFilterResponse";
import BtnFilter from "../../buttons/btnFilter.component/BtnFilter";
import { initials } from "../../../helpers/Inicials";
import {
    HistoryEntities,
    ProjectHistoryEntities,
} from "../../../models/History/HistoryEntities";
import { ProjectHistoryActions } from "../../../models/History/HistoryActions";

export const HISTORY_PAGE_SIZE = 5;
const FULL_SCREEN_PAGE_SIZE = 20;

interface IHistoryProjectProps {
    records: IProjectHistoryDisplay[];
    loadPage: (
        skip: number,
        take: number,
        filters: IGetProjectHistoryFilters,
        append: boolean
    ) => Promise<boolean>;
    userAccess: IHasPermission[];
}

const History: React.FC<IHistoryProjectProps> = ({
    records,
    loadPage,
    userAccess,
}) => {
    const { id } = useParams();
    const [skip, setSkip] = useState<number>(0);
    const [haveMoreRecords, setHaveMoreRecords] = useState<boolean>(true);
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false);
    const { store } = useContext(Context);

    //Фильтры
    const [searchValue, setSearchValue] = useState<string>("");
    const [filterCounter, setFilterCounter] = useState<number>(0);
    const [filterData, setFilterData] = useState<IFilter[]>([]);
    const [resetFilterData, setResetFilterData] = useState<IFilter[]>([]);
    const [checkedFilters, setCheckedFilters] = useState<any>({
        responsible: undefined,
        date: undefined,
    });
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [dates, setDates] = useState(null);

    // Ограничение прав
    const [viewHistory, setViewHistory] = useState(false);

    useEffect(() => {
        (async () => {
            let data = await store.getDataForFilter({
                projectId: Number(id),
                date: true,
                responsible: true,
                status: false,
                typeTask: false,
                priority: false,
                role: false,
                fileType: false,
            });
            if (!data) return;
            data.push({
                label: "Тип объекта",
                nameForFilter: "entityTypes",
                items: Array.from(ProjectHistoryEntities).map(
                    (entity, index) => {
                        return { label: entity[1], id: index, checked: false };
                    }
                ),
            });
            data.push({
                label: "Тип изменения",
                nameForFilter: "actionTypes",
                items: Array.from(ProjectHistoryActions).map(
                    (action, index) => {
                        return { label: action[1], id: index, checked: false };
                    }
                ),
            });
            //Преобразования Имя пользователя к формату ФИО
            let a: any = data?.map((i) => {
                if (i.type && i.type === "users") {
                    let c: any = i.items?.map((x: any) => {
                        return { code: x.code, name: initials(x.name!) };
                    });
                    if (c?.length) {
                        i.items = c;
                        return i;
                    } else {
                        return i;
                    }
                } else {
                    return i;
                }
            });

            let resetData = structuredClone(a);
            setFilterData(a);
            setResetFilterData(resetData);
        })();
    }, []);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "ProjectHistory") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewHistory(true);
                    }
                });
            }
        });

        if (store.user.email == "admin@admin.adm") {
            setViewHistory(true);
        }
    }, [userAccess]);

    useEffect(() => {
        (async () => {
            // Если перешли в полноэкранный режим, догружаем задачи до полной страницы
            if (openOnFullWindow && records.length < FULL_SCREEN_PAGE_SIZE) {
                await loadRecords(0, checkedFilters, false);
            }
        })();
    }, [openOnFullWindow]);

    useEffect(() => {
        setOpenOnFullWindow(false);
    }, [id]);

    const handleScrolledToBottom = async () => {
        if (haveMoreRecords) {
            await loadRecords(skip, checkedFilters, true);
        }
    };

    const onCancelClick = async () => {
        resetFilterData && setFilterData(structuredClone(resetFilterData));
        let nullFilters = {
            projectId: Number(id),
        };
        setCheckedFilters({ ...nullFilters });
        setSearchValue("");
        setSelectedUsers(null);
        setDates(null);
        setFilterCounter(0);

        await loadRecords(0, nullFilters, false);
    };

    async function onSearchSubmit(value: string) {
        let newFilters = { ...checkedFilters };
        newFilters.name = value;
        setCheckedFilters({ ...newFilters });
        await loadRecords(0, newFilters, false);
    }

    const applyFilters = async () => {
        await loadRecords(0, checkedFilters, false);
    };

    const loadRecords = async (
        from: number,
        appliedFilters: any,
        append: boolean
    ) => {
        const pageSize = openOnFullWindow
            ? FULL_SCREEN_PAGE_SIZE
            : HISTORY_PAGE_SIZE;
        const filterEntityTypes = appliedFilters.entityTypes
            ? appliedFilters.entityTypes.map(
                  (eId: number) => Array.from(ProjectHistoryEntities)[eId][0]
              )
            : undefined;
        const filterActionTypes = appliedFilters.actionTypes
            ? appliedFilters.actionTypes.map(
                  (aId: number) => Array.from(ProjectHistoryActions)[aId][0]
              )
            : undefined;
        const filters: IGetProjectHistoryFilters = {
            projectId: Number(id),
            dateFrom:
                (appliedFilters.date && appliedFilters.date[0]) ?? undefined,
            dateTo:
                (appliedFilters.date && appliedFilters.date[1]) ?? undefined,
            userIds: appliedFilters.responsible ?? undefined,
            entityTypes: filterEntityTypes,
            actionTypes: filterActionTypes,
            entityName: appliedFilters.name ?? undefined,
        };
        setHaveMoreRecords(await loadPage(from, pageSize, filters, append));
        setSkip(from + pageSize);
    };

    return (
        <div
            id="main-history-widget"
            className={`project-history-widget ${
                openOnFullWindow
                    ? "widget_wrapper--full__window"
                    : "widget_wrapper"
            }`}
        >
            <div
                className={
                    openOnFullWindow ? "widget_wrapper-full__window--block" : ""
                }
            >
                <div className={'widgets_header--container'}> {openOnFullWindow ? (
                    <div
                        onClick={() => setOpenOnFullWindow(false)}
                        className="widgets_header--open__block"
                    >
                        <img src={CloseBlockWindow}/>
                    </div>
                ) : (
                    <div
                        onClick={() => setOpenOnFullWindow(true)}
                        className="widgets_header--open__block"
                    >
                        <img src={OpenBlock}/>
                    </div>
                )}</div>
                <div className="widgets_header">

                    <div className="widjets_header_left">

                        <h2 className="widget-card-header-style">
                            История проекта
                        </h2>
                    </div>
                    <div className="widjets_header_rigth">
                        <Search
                            defaultValue={searchValue}
                            onSubmitFunc={onSearchSubmit}
                            changeSearchValue={setSearchValue}
                        />
                        <BtnFilter
                            filterData={filterData}
                            setFilterData={setFilterData}
                            applayFilters={applyFilters}
                            checkedFilters={checkedFilters}
                            setCheckedFilters={setCheckedFilters}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            dates={dates}
                            setDates={setDates}
                            counter={filterCounter}
                            setCounter={setFilterCounter}
                            showApplyButton={true}
                        />
                        {(filterCounter !== 0 || searchValue) && (
                            <CancelButton onClickFunc={onCancelClick}/>
                        )}
                    </div>
                </div>
                {viewHistory ? (
                    <HistoryTable
                        openOnFullWindow={openOnFullWindow}
                        records={records}
                        onScrolledToBottom={handleScrolledToBottom}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default observer(History);
