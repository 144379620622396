import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { useParams } from 'react-router-dom';
import "../../styles/filesComponent.pc.css";
import { Context } from "../../../index";
import Search from '../../Shared/Search/Search';
import BtnAdd from "../../buttons/btnAdd.component/BtnAdd";
import './Files.css';
import { IFileFilterOptions, IFileFilters, IFilesDisplay } from "../../../models/FileModels";
import BtnCancel from "../../buttons/CancelButton/CancelButton";
import BtnDelete from "../../buttons/btnDelete.component/BtnDelete";
import BtnDownload from "../../buttons/btnDownload.component/BtnDownload";
import { ProjectContext } from '../../../pages/Project/Project';
import ConfirmationWindow from '../../Shared/ConfirmationWindow/ConfirmationWindow';
import Checkbox from '../../Shared/Checkbox/Checkbox';
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";
import OpenBlock from "../../../assets/openBlock.svg";
import {AppContext} from "../../../App";
import {IHasPermission} from "../../../models/IChekRole";


interface IFilesProps {
    files: IFilesDisplay | undefined;
    filterOptions: IFileFilterOptions | undefined;
    updateFilters: (filters: IFileFilters) => void;
    userAccess: IHasPermission[];
}

const Files: React.FC<IFilesProps> = ({ files, filterOptions, updateFilters, userAccess}) => {
    const { store } = useContext(Context);
    const { loadFiles } = useContext(ProjectContext);
    const { showToast } = useContext(AppContext);
    const { id } = useParams();
    const [deletFilesIds, setDeletFilesIds] = useState<number[]>([]);
    const selectedFileId = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false);

    // Ограничение прав
    const [viewFiles, setViewFiles] = useState(false);
    const [addFile, setAddFile] = useState(false);
    const [delFile, setDelFile] = useState(false);

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Escape') {
                event.preventDefault();
                onCancelClick() 
            }
        };

        userAccess.forEach((xx) => {
            if (xx.functionCode == "ProjectFileAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setAddFile(true);
                    }
                    if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        setDelFile(true);
                    }
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewFiles(true);
                    }
                })
            }
        })

        if (store.user.email == "admin@admin.adm") {
            setViewFiles(true);
            setAddFile(true);
            setDelFile(true);
        }

        document.addEventListener('keydown', keyDownHandler);
        // Clean up event listener
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [userAccess]);

    const filters = useRef<IFileFilters>({
        projectId: Number(id),
        fileType: [],
        name: ''
    })

    const handleCancelClick = () => {
        clearSelectedFiles()

    };

    const handleAddClick = () => {

    };

    const clearSelectedFiles = () => {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]') as unknown as any[];
        for (let item of checkboxes) {
            if (item.getAttribute('checkboxgroup') === "file-selection") {
                item.checked = false;
            }
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
    };

    const handleFilterClick = (event: any) => {

    };

    const handleDeleteClick = (event: any) => {

    };

    const handleFileSelection = (fileId: number, event: any) => {
        let ids = deletFilesIds;
        if (event.target.checked) {
            ids.push(fileId);
        } else {
            let index = ids.findIndex(i => i === fileId);
            if (index !== -1) {
                ids.splice(index, 1);
            }
        }
        setDeletFilesIds([...ids]);
    };

    const handleDownloadClick = (event: any) => {
        event.stopPropagation();
    };

    function onSearchSubmit(value: string) {
        let newFilters = { ...filters.current }
        newFilters.name = value
        filters.current = newFilters
        updateFilters(filters.current)
    }

    function onFilterClick() {

    }

    function onCancelClick() {
        setSearchValue('');
        (async () => {
            await loadFiles({
                projectId: Number(id),
                name: '',
                fileType: []
            })
        })();
    }

    function onDelete() {
        deletFilesIds?.length && (async () => {
            await store.deleteFiles(deletFilesIds);
            await loadFiles({
                projectId: Number(id),
                name: '',
                fileType: []
            })
        })();
        setDeletFilesIds([]);
    }

    function onAddClick(e: any) {
        (async () => {
            await store.addFileToProject(e.target.files[0], Number(id), 1);
            await loadFiles({
                projectId: Number(id),
                name: '',
                fileType: []
            })

        })();
    }

    function onDownloadClick(itemId: number, type: string, name: string) {
        (async () => {
            await store.downloadFile(itemId.toString())
                .then((i: any) => {
                    let link = document.createElement('a');
                    link.download = `${name}${type}`;
                    let blob = new Blob([i]);
                    link.href = URL.createObjectURL(blob);
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
        })()
    }
    const onConfirm = () => {
        onDelete();
        setOpenConfirmWindow(false);
    }
    const onCancel = () => {
        setOpenConfirmWindow(false);
    }

    const openConfWindow = () => {
        if (delFile) {
            setOpenConfirmWindow(true);
        } else {
            showToast("У вас нет прав для удаления файла!")
        }
    }
    return (
        <div
            id='project-files-widget-component'
            className={`project-files-widget-component ${openOnFullWindow ? 'widget_wrapper--full__window' : 'widget_wrapper'}`}>
            <div className={openOnFullWindow ? 'widget_wrapper-full__window--block' : ''}>
                {openConfirmWindow && (deletFilesIds?.length === 1 ?
                    <ConfirmationWindow headerText='Удалить файл?'
                         bodyText='Вы уверены, что хотите удалить файл?'
                         confirmText='Да'
                         cancelText='Нет'
                         onConfirm={onConfirm}
                         onCancel={onCancel}/>
                :
                    <ConfirmationWindow headerText='Удалить файлы?'
                                        bodyText='Вы уверены, что хотите удалить файлы?'
                                        confirmText='Да' cancelText='Нет' onConfirm={onConfirm} onCancel={onCancel}/>)
                }
                <div className={'widgets_header--container'}>
                    {openOnFullWindow ?
                    <div onClick={() => setOpenOnFullWindow(false)} className='widgets_header--open__block'>
                        <img src={CloseBlockWindow}/>
                    </div>
                    :
                    <div onClick={() => setOpenOnFullWindow(true)} className='widgets_header--open__block'>
                        <img src={OpenBlock}/>
                    </div>
                }
                </div>
                <div className='widgets_header'>

                    <div className='widgets_header--name__files'>
                        <h2 className='widget-card-header-style'>
                            Файлы
                        </h2>
                    </div>
                    <div className='widjets_header_rigth'>
                        <Search defaultValue={searchValue} onSubmitFunc={onSearchSubmit}
                                changeSearchValue={setSearchValue}/>
                        {searchValue && <BtnCancel onClickFunc={onCancelClick}/>}
                        {deletFilesIds?.length !== 0 && <BtnDelete onClickFunc={openConfWindow} permission={delFile}/>}
                        <BtnAdd type={'file'} onClickFunc={onAddClick} permission={addFile}/>

                    </div>
                </div>
                <div id='files-widget-items-container' className='files-widget-container'>
                    {viewFiles && files?.files?.map((item, index) => (
                        <div key={item.id} className="files-widget-file-card">
                            <Checkbox id={item.id} handleSelection={handleFileSelection}/>
                            <div title={`${item.name}${item.type}`} className='ellipses files-widget-file-name-text'>
                                {/* <div className='files-widget-file-name-text'>{item.name}</div>
                                <div className='files-widget-file-type-text'>{item.type}</div> */}
                                {`${item.name}${item.type}`}
                                {/* <p className='ellipses'>{item.type}</p> */}
                            </div>

                            <div style={{display: "flex", verticalAlign: "middle", marginLeft: "auto"}}>
                                <BtnDownload onClickFunc={() => (onDownloadClick)} itemId={item.id} url={item.url}
                                             type={item.type} name={item.name} permission={viewFiles}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default observer(Files);