import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import "../../styles/team.pc.css";
import "../../styles/table.pc.css";
import Search from "../Search/Search";
import "../../../globalstyles/dataTableStyles.css";
import PersonLineDisplay from "../PersonLineDisplay";
import { Context } from "../../../index";
import { initials } from "../../../helpers/Inicials";

const PAGE_SIZE = 5;

export interface ITeamFilter {
    projectId: number;
    role: number[] | undefined;
    name: string | undefined;
}

interface IMember {
    id: number;
    name: string;
    surname: string;
    middleName: string | undefined;
    role: string;
    photoId: number | undefined;
}

interface IPersonSelectProps {
    members: IMember[];
    scrollEnded: boolean;
    loadPage: (skip: number, take: number, filters: ITeamFilter) => void;
    updateFilters: (filters: ITeamFilter) => void;
    selectPerson: (personId: number) => void;
}

const PersonSelector: React.FC<IPersonSelectProps> = ({
    members,
    scrollEnded,
    loadPage,
    selectPerson,
}) => {
    const { store } = useContext(Context);
    const page = useRef<number>(0);
    const filters = useRef<ITeamFilter>({
        projectId: store.currentProjectId,
        role: undefined,
        name: "",
    });
    const [searchSubstring, setSearchSubstring] = useState<string>("");
    const onScroll = (e: any) => {
        checkIfScrolledToBottom(e);
    };
    const [membersForDisplay, setMembersForDisplay] = useState<IMember[]>([]);

    function checkIfScrolledToBottom(e: any) {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        if (1 + scrollTop + offsetHeight >= scrollHeight && !scrollEnded) {
            page.current++;
            loadPage(page.current * PAGE_SIZE, PAGE_SIZE, filters.current);
        }
    }

    useEffect(() => {
        const search = searchSubstring?.toLowerCase();
        const searchableFields: (keyof IMember)[] = [
            "name",
            "surname",
            "middleName",
            "role",
        ];
        setMembersForDisplay(
            members?.filter((member) =>
                searchableFields.some(
                    (field) =>
                        typeof member[field] === "string" &&
                        String(member[field])?.toLowerCase().includes(search)
                )
            )
        );
    }, [members, searchSubstring]);

    function onPersonClick(key: number, e: any) {
        selectPerson(key);
        getPersonMore(e);
    }

    function getPersonMore(e: any) {
        if (members.length <= 5) {
            checkIfScrolledToBottom(e);
        }
    }
    return (
        <div className="person-selector-container">
            <div className="widgets_header">
                <Search
                    onSubmitFunc={() => {}}
                    defaultValue={searchSubstring}
                    changeSearchValue={setSearchSubstring}
                />
            </div>
            <div
                onScroll={onScroll}
                style={{ overflowY: "auto", height: "130px" }}
            >
                <table>
                    <tbody>
                        {membersForDisplay.map((member) => (
                            <tr
                                key={member.id}
                                onClick={(e) => onPersonClick(member.id, e)}
                                className="person-selection-item"
                            >
                                <td>
                                    <PersonLineDisplay
                                        name={initials(member.name)} // TODO нужно поменять на 3 переменных
                                        photoId={member.photoId}
                                    />
                                </td>
                                <td className="task-type-text-position ellipses">
                                    {member.role}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default observer(PersonSelector);
