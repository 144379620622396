import { AxiosResponse } from "axios";

interface oauth{
    clientId?: string,
    systemUrl?: string
}

export class DataStore {
    constructor(axiosResp: AxiosResponse<any, any>) {
        if(axiosResp){
            let resp : oauth = axiosResp.data;
            this.OAUTH_CLIENT_ID = resp.clientId;
            this.EXTERNAL_URL = resp.systemUrl;
        }
    }
    OAUTH_CLIENT_ID?: string = '';
    EXTERNAL_URL?:string = '';
};

