import React, {FC, useContext, useEffect, useState} from "react";
import {IBoardTaskDisplay} from "../../../models/IBoardTaskDisplay";
import TaskType from "../../Shared/TaskType";
import TaskPriority from "../../Shared/TaskPriority";
import "./BoardTask.css";
import {ProgressBar} from "primereact/progressbar";
import {BoardContext} from "../../../pages/Board/Board";
import TaskTeg from '../../Shared/TaskTeg';
import UserPicture from '../../../assets/user.svg'

import BtnMore from "../../buttons/btnMore.component/BtnMore";
// import BtnMoreGray from "../../buttons/btnMore.component/BtnMoreGray"

import {Context} from "../../..";
import ConfirmationWindow from "../../Shared/ConfirmationWindow/ConfirmationWindow";
import TaskAddEditForm from "../../Task/TaskAddEditForm/TaskAddEditForm";
import {Link} from "react-router-dom";
import {AppContext} from "../../../App";
import {IHasPermission} from "../../../models/IChekRole";
import Avatar from "../../Shared/Avatar/Avatar";
import {getFileUrlFromId} from "../../../helpers/getFileUrlFromId";
import {IRestrictAccess} from "../../../models/IRestrictAccess";

interface IBoardTaskProps {
    task: IBoardTaskDisplay,
    onTaskDragEnd: () => void,
    isSelectable: boolean,
    isSelected: boolean,
    userAccess: IHasPermission[],
    abbreviationProject: string | undefined,
    columnAccess: boolean,
    boardsData?: IRestrictAccess | undefined
}

const BoardTask: FC<IBoardTaskProps> = ({
                                            task,
                                            onTaskDragEnd,
                                            isSelectable,
                                            isSelected,
                                            userAccess,
                                            abbreviationProject,
                                            columnAccess,
                                            boardsData
                                        }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const {handleSelectTask, boardIdNumber, handleTaskAction, boardsForProxy} = useContext(BoardContext);
    const {store} = useContext(Context);
    const [dataForEdit, setDataForEdit] = useState(['Редактировать', 'Переместить', 'Удалить'])
    const [open, setOpen] = useState(false);

    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [hideSelf, setHideSelf] = useState<boolean>(false);
    const {handleDeleteTask, handleMoveTask, columns} = useContext(BoardContext);
    const [openConfirmWindow, setOpenConfirmWindow] = useState(false);
    const {showToast} = useContext(AppContext);

    // Ограничение прав
    const [createTask, setCreateTask] = useState(false);
    const [editTask, setEditTask] = useState(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") { //Канбан
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditTask(true);
                    }
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setCreateTask(true);
                    }
                })
            }
        })

        if (store.user.email == "admin@admin.adm") {
            setEditTask(true);
            setCreateTask(true);
        }

        console.log("user-user", userAccess);
    }, [userAccess]);

    const columnsChoises = columns.filter(
        (c) => c.tasks.find((t) => t.id === task.id) === undefined
    );


    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        // Без setTimeout не работает, т.к. задача исчезает раньше
        // чем начинается drag и он сразу заканчивается
        setTimeout(() => {
            (e.target as any).classList.add('drag-hidden');
        }, 0);
        e.dataTransfer.setData(
            "application/json",
            JSON.stringify({
                taskId: task.id,
            })
        );
    };

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            (e.target as any).classList.remove('drag-hidden');
        }, 100);
        onTaskDragEnd();
    };

    function gerPersonShortName(): string {
        const name = task.responsibleName.split(" ")[0] + " " +
            task.responsibleName.split(" ")[1]?.slice(0, 1) + "." +
            task.responsibleName.split(" ")[2]?.slice(0, 1) + ".";
        const MAX_SIZE = 10;

        const newName = name.length > MAX_SIZE
            ?
            name.slice(0, MAX_SIZE).slice(MAX_SIZE - 1, MAX_SIZE) == " "
                ?
                name.slice(0, MAX_SIZE - 1) + "..."
                :
                name.slice(0, MAX_SIZE) + "..." : name;

        return newName;
    }

    const taskPriorityDisplay = () => {
        const styleItem = JSON.parse(task.priority.color);
        return (
            <TaskPriority name={task.priority.name} color={styleItem.color} icon={styleItem.icon}/>
        );
    };

    const taskTypeDisplay = () => {
        const styleItem = JSON.parse(task.type.color);
        return (
            <TaskType name={task.type.name} color={styleItem.color} icon={styleItem.icon}/>
        );
    };

    const taskTagsDisplay = (rowData: any) => {
        return <TaskTeg name={rowData.name} color={rowData?.color ? rowData.color : ""}/>;
    };

    function onEditClick(e: any) {
        console.log(e.target)
        setOpen(false);
        if (e.target.innerText === 'Редактировать') {
            store.setDrag(false);
            setHideSelf(true);
            setShowEditForm(!showEditForm);
        } else if (e.target.innerText === 'Переместить') {
        } else if (e.target.innerText === 'Удалить') {
            setOpenConfirmWindow(true);
        }
    }

    function onFormClick(event: any) {
        event.stopPropagation();
    }

    function onEditFormClose() {
        store.setDrag(true);
        setShowEditForm(false);
        setHideSelf(false);
    }

    function onOverlayClick() {
        // onEditFormClose();
    }

    const onConfirm = () => {
        handleDeleteTask(task.id);
        setOpenConfirmWindow(false);
    }
    const onCancel = () => {
        setOpenConfirmWindow(false);
    }

    return (
        <div id={`task-${task.id}`} className="board-task-card" draggable={store.isDrag && !columnAccess ? true : false}
             onDragStart={store.isDrag && !columnAccess ? onDragStart : undefined}
             onDragEnd={store.isDrag && !columnAccess ? onDragEnd : undefined}
        >
            {openConfirmWindow && <ConfirmationWindow headerText='Удалить задачу?'
                                                      bodyText='Вы уверены, что хотите удалить задачу?'
                                                      confirmText='Да' cancelText='Нет' onConfirm={onConfirm}
                                                      onCancel={onCancel}/>}
            {showEditForm ? (
                <div className="full-screen-overlay" onClick={onOverlayClick}>
                    <div className="popup-form-background" onClick={onFormClick}>
                        <TaskAddEditForm mode={"edit"} taskId={task.id} closeFunc={onEditFormClose}
                                         columnId={task.columnPosition} onTaskAction={handleTaskAction}
                                         permission={editTask}/>
                    </div>
                </div>
            ) : (
                <></>
            )}

            <div className="dash__board--form-column__tasks--elem__header">
                <div className="dash__board--form-column__tasks--elem__header__shortDescription">

                    <div className="task-abbr-container">
                        <div
                            className="task__abbr__board">{abbreviationProject}-{task.id < 10 ? '00' + task.id : (task.id < 100 ? '0' + task.id : task.id)}</div>
                        <div className="task__short--description__button">
                            <BtnMore onClickFunc={onEditClick} data={dataForEdit} typeForKey='nameProj' open={open}
                                     setOpen={setOpen} columnsChoices={columnsChoises} boardChoices={boardsForProxy}
                                     taskId={task.id} userAccess={userAccess} columnAccess={columnAccess}
                                     boardsData={boardsData}/>
                        </div>
                    </div>
                    <div className="task__name__board">
                        {isSelectable ? (
                            isSelected ? (
                                <input
                                    className="files-widget-checkbox"
                                    onChange={() => handleSelectTask(task.id)}
                                    value={task.id}
                                    type="checkbox"
                                    checked
                                />
                            ) : (
                                <input
                                    className="files-widget-checkbox"
                                    onChange={() => handleSelectTask(task.id)}
                                    value={task.id}
                                    type="checkbox"
                                />
                            )
                        ) : (
                            <></>
                        )}
                        <Link className={'task__name__board'}
                              title={task?.name}
                              to={`/board/${boardIdNumber}/task/${task.id}`}
                              draggable="false">
                            {task?.name?.length > 30 ? task?.name?.slice(0, 30) + "..." : task?.name}
                        </Link>
                    </div>
                    {task?.description?.length > 20 ?
                        (<p title={task?.description} className="task--description__board">
                            {task?.description?.length > 20 ? task?.description?.slice(0, 20) + "..." : task?.description}
                        </p>) :
                        (<p className="task--description__board">
                            {task?.description}
                        </p>)}

                </div>
            </div>
            <div className="dash__board--form-column__tasks__menu">
                <div className="dash__board--form-column__tasks__priority--list">
                    <div className="dash__board--form-column__tasks__priority--list__item">
                        {taskTypeDisplay()}
                    </div>
                    <div>
                        {taskPriorityDisplay()}
                    </div>
                </div>
                <div className="dash__board--form-column__tasks--elem__teg--list">
                    {
                        task?.tags && (
                            <>
                                {task?.tags.length > 3 ? (
                                    <>
                                        {task?.tags.slice(0, 3).map((tag, index) => (
                                            <div key={index}
                                                 className="widget__task--information__priority--type--block__list--margin">
                                                {taskTagsDisplay(tag)}
                                            </div>
                                        ))}
                                        <div
                                            className="widget__task--information__tegs--view__teg--tooltip"
                                            onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)}
                                        >
                                            <p>{`+${task?.tags.length - 3}`}</p>
                                            {showTooltip && (
                                                <div className="widget__task--information__tegs--view__teg--tooltip__open">
                                                    {task?.tags.slice(3).map((tag, index) => (
                                                        <div key={index}
                                                             className="widget__task--information__tegs--view__teg--tooltip__open--teg">
                                                            {taskTagsDisplay(tag)}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    task?.tags.map((tag, index) => (
                                        <div key={index}
                                             className="widget__task--information__priority--type--block__list--margin">
                                            {taskTagsDisplay(tag)}
                                        </div>
                                    ))
                                )}
                            </>
                        )
                    }
                </div>
            </div>
            {/* <div>
            <p>
                Дата начала: {task.startDate}
            </p>
            <p>
                Дата конца: {task.endDate}
            </p>
            </div> */}
            <div className="dash__board--form-column__tasks--elem__bottom">
                <div className="dash__board--form-column__tasks--elem__bottom--flex">
                    <div className="dash__board--form-column__tasks--elem__bottom--name">
                        <Avatar size="s" url={getFileUrlFromId(task.responsiblePhotoId)}/>
                        <p title={task?.responsibleName}
                           className="dash__board--form-column__tasks--elem__bottom--flex__name">
                            {gerPersonShortName()}
                        </p>
                    </div>
                    <div className="dash__board--form-column__tasks--elem__bottom--flex__progress__bar">
                        <div className="dash__board--form-column__tasks--elem__bottom--flex__progress__bar--block">
                            <div
                                className="dash__board--form-column__tasks--elem__bottom--flex__progress__bar--block__header">
                                {task.percentageCompletion}%
                            </div>
                            <div style={{width: "100%"}}>
                                <ProgressBar
                                    value={task.percentageCompletion}
                                    style={{height: '8px', fontSize: '0px'}}
                                ></ProgressBar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoardTask;
