export type HistoryItemValueType =
    | string
    | string[]
    | boolean
    | INameStyle
    | IPerson
    | IPerson[]
    | INameColor[]
    | IAccess[];

export enum HistoryItemType {
    string = "string",
    stringArray = "stringArray",
    bool = "bool",
    date = "date",
    ITaskType = "ITaskType",
    IPriority = "IPriority",
    IStatus = "IStatus",
    INameColorArray = "INameColorArray",
    IPerson = "IPerson",
    IPersonArray = "IPersonArray",
    color = "color",
    markdown = "markdown",
    IAccessArray = "IAccessArray",
}

export interface IHistoryRecord {
    oldData: IHistoryItem[] | null;
    newData: IHistoryItem[] | null;
}

export interface IHistoryItem {
    caption: string;
    value?: HistoryItemValueType;
    type: HistoryItemType;
}

export interface IHistoryItemDifference {
    caption: string;
    oldValue: HistoryItemValueType | null;
    newValue: HistoryItemValueType | null;
    type: HistoryItemType;
}

export interface INameStyle {
    name: string;
    style: string;
}

export interface ITaskType extends INameStyle {}

export interface IPriority extends INameStyle {}

export interface IStatus extends INameStyle {}

export interface INameColor {
    name: string;
    color: string;
}

export interface IPerson {
    id: number;
    name: string;
    surname: string;
    middlename?: string;
    photoId: number;
}

export interface IAccess {
    name: string;
    granted: boolean;
}
