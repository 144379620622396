import { FC, useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../..";
import { IFullUserInfo, IUser } from "../../../models/IUser";
import "./UserPopup.css";
import Avatar from "../Avatar/Avatar";
import { getFileUrlFromId } from "../../../helpers/getFileUrlFromId";
import EmailImage from "../../../assets/email.svg";
import useOutsideAlerter from "../../../customHooks/useOutsideAlert";

interface UserPopupProps {
    userId: number;
    onOverlayClick: () => void;
    onLoaded: () => void;
}

const  UserPopup: FC<UserPopupProps> = ({ userId, onOverlayClick, onLoaded }) => {
    const { store } = useContext(Context);
    const [user, setUser] = useState<IFullUserInfo>();

    const outsideAlerterRef: any = useOutsideAlerter(onOverlayClick);

    useEffect(() => {
        (async () => {
            const userRes = await store.getUser(userId);
            setUser(userRes);
            onLoaded();
        })();
    }, [userId]);

    return (
        <div>
            {/* Хотел сделать фичу с запретом прокрутки колеса если попап показывается, но оона мешает аутсайдАлертеру */}
            {/* <div onWheel={(e) => {e.stopPropagation(); e.preventDefault()}} className="user-popup-overlay"/> */}
            <div className="user-popup-container" ref={outsideAlerterRef}>
                <div className="top-panel">
                    <div>
                        <Avatar url={getFileUrlFromId(user?.photoId)} size="xl" />
                    </div>
                    <div className="user-info-container">
                        <p className="user-info-name">{`${user?.surname ?? ""} ${user?.name ?? ""}`}</p>
                        <p className="user-info-role">{`${user?.role?.name ?? ""}`}</p>
                    </div>
                </div>
                <div className="bottom-panel">
                    <div className="email-container">
                        <img
                            src={EmailImage}
                            alt="Электронная почта"
                            width="18px"
                            height="18px"
                        ></img>
                        {`${user?.email ?? ""}`}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserPopup;
