import {FC, useContext, useEffect, useState} from "react";
import {Context} from "../../..";
import "./SettingsPopup.css";
import {useNavigate} from "react-router-dom";
import ExitButton from "../../buttons/ExitButton/ExitButton";
import Avatar from "../../Shared/Avatar/Avatar";
import useOutsideAlerter from "../../../customHooks/useOutsideAlert";
import {getFileUrlFromId} from "../../../helpers/getFileUrlFromId";
import FunctionSystem from "../../../models/functionCode/functionsSystem";
import {ICheckRole} from "../../../models/IChekRole";

interface ISettingsPopupProps {
    onClose: () => void;
    onSettingsClick: () => void;
    onPasswordChangeClick: () => void;
}

const SettingsPopup: FC<ISettingsPopupProps> = ({
                                                    onClose,
                                                    onSettingsClick,
                                                    onPasswordChangeClick
                                                }) => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const outsideAlerterRef: any = useOutsideAlerter(onClose);
    const [version, setVersion] = useState<any>()

    useEffect(() => {
        (async () => {
            try {
                const data = await store.getVersion();
                setVersion(data)
            } catch (error) {
                console.log('error',error)
            }
        })();
    }, []);

    const handleLogout = async () => {
        try {
            const success: boolean | undefined = await store.logout();
            if (success) {
                navigate("/login");
            } else {
                console.error("Logout failed");
            }
        } catch (error) {
            console.error("Logout failed", error);
        }
    };

    return (
        <div className="settings-popup-container" ref={outsideAlerterRef}>
            <div>
                <div style={{display: "flex"}}>
                    <Avatar size="s" url={getFileUrlFromId(store.user.photoId)}/>
                    <a className="a_header_name_16 settings-popup-username">
                        {store.user.name} {store.user.surname}
                    </a>
                </div>
                <div className="settings-popup-btn" onClick={onSettingsClick}>
                    <div className="settings-img"/>
                    <p>Настройки</p>
                </div>
                <div className="settings-popup-btn" onClick={onPasswordChangeClick}>
                    <div className="change-password-img"></div>
                    <p>Смена пароля</p>
                </div>
                <div className="settings-popup-btn" onClick={() => navigate("/feedback")}>
                    <div className="feedback-img"></div>
                    <p>Обратная связь</p>
                </div>
            </div>
            <ExitButton onClickFunc={handleLogout}/>
            <div className="settings-popup-version" ><p>Версия: {version?.appVersion}</p></div>
        </div>
    );
};

export default SettingsPopup;
