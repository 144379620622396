import React, {createElement, FC, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import "./InputChat.css"
import SimpleMDEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import {Context} from '../../..';
import {useParams} from 'react-router-dom';
import UserPicture from '../../../assets/user.svg'
import {
    IFilesDisplay,
    IFileTaskFilters,
    IGetFilesTaskDisplayData
} from "../../../models/FileModels";
import {IChatMessage, IMessages} from '../../../models/IChatMessage';
import {format} from 'date-fns'
import MarkdownPreview from '../MarkdownPreview/MarkdownPreview';
import {IHasPermission} from "../../../models/IChekRole";
import {AppContext} from "../../../App";

import unknown_svg from "../../../assets/FileIcons/unknown.svg"
import xsl_svg from "../../../assets/FileIcons/xsl.svg"
import xls_svg from "../../../assets/FileIcons/xsl.svg"
import xlsx_svg from "../../../assets/FileIcons/xsl.svg"

import avi_svg from "../../../assets/FileIcons/avi.svg"
import bmp_svg from "../../../assets/FileIcons/bmp.svg"
import crd_svg from "../../../assets/FileIcons/crd.svg"
import csv_svg from "../../../assets/FileIcons/csv.svg"
import dll_svg from "../../../assets/FileIcons/dll.svg"
import doc_svg from "../../../assets/FileIcons/doc.svg"
import docx_svg from "../../../assets/FileIcons/docx.svg"
import dwg_svg from "../../../assets/FileIcons/dwg.svg"
import eps_svg from "../../../assets/FileIcons/eps.svg"
import exe_svg from "../../../assets/FileIcons/exe.svg"
import flv_svg from "../../../assets/FileIcons/flv.svg"
import gif_svg from "../../../assets/FileIcons/gif.svg"
import html_svg from "../../../assets/FileIcons/html.svg"
import iso_svg from "../../../assets/FileIcons/iso.svg"
import java_svg from "../../../assets/FileIcons/java.svg"
import jpg_svg from "../../../assets/FileIcons/jpg.svg"
import mdb_svg from "../../../assets/FileIcons/mdb.svg"
import mid_svg from "../../../assets/FileIcons/mid.svg"
import mov_svg from "../../../assets/FileIcons/mov.svg"
import mp3_svg from "../../../assets/FileIcons/mp3.svg"
import mp4_svg from "../../../assets/FileIcons/mp4.svg"
import mpeg_svg from "../../../assets/FileIcons/mpeg.svg"
import pdf_svg from "../../../assets/FileIcons/pdf.svg"
import png_svg from "../../../assets/FileIcons/png.svg"
import ppt_svg from "../../../assets/FileIcons/ppt.svg"
import pptx_svg from "../../../assets/FileIcons/ppt.svg"
import ps_svg from "../../../assets/FileIcons/ps.svg"
import psd_svg from "../../../assets/FileIcons/psd.svg"
import pub_svg from "../../../assets/FileIcons/pub.svg"
import rar_svg from "../../../assets/FileIcons/rar.svg"
import raw_svg from "../../../assets/FileIcons/raw.svg"
import rss_svg from "../../../assets/FileIcons/rss.svg"
import svg_svg from "../../../assets/FileIcons/svg.svg"
import tiff_svg from "../../../assets/FileIcons/tiff.svg"
import txt_svg from "../../../assets/FileIcons/txt.svg"
import json_svg from "../../../assets/FileIcons/txt.svg"
import wav_svg from "../../../assets/FileIcons/wav.svg"
import wma_svg from "../../../assets/FileIcons/wma.svg"
import xml_svg from "../../../assets/FileIcons/xml.svg"
import zip_svg from "../../../assets/FileIcons/zip.svg"


const fileIcons = {
    xsl_svg,
    xls_svg,
    xlsx_svg,
    avi_svg,
    bmp_svg,
    crd_svg,
    csv_svg,
    dll_svg,
    doc_svg,
    docx_svg,
    dwg_svg,
    eps_svg,
    exe_svg,
    flv_svg,
    gif_svg,
    html_svg,
    iso_svg,
    java_svg,
    jpg_svg,
    mdb_svg,
    mid_svg,
    mov_svg,
    mp3_svg,
    mp4_svg,
    mpeg_svg,
    pdf_svg,
    png_svg,
    ppt_svg,
    pptx_svg,
    ps_svg,
    psd_svg,
    pub_svg,
    rar_svg,
    raw_svg,
    rss_svg,
    svg_svg,
    tiff_svg,
    txt_svg,
    json_svg,
    wav_svg,
    wma_svg,
    xml_svg,
    zip_svg
}

type ToolbarButton =
    'bold'
    | 'italic'
    | 'quote'
    | 'unordered-list'
    | 'ordered-list'
    | 'link'
    | 'image'
    | 'strikethrough'
    | 'code'
    | 'table'
    | 'redo'
    | 'heading'
    | 'undo'
    | 'heading-bigger'
    | 'heading-smaller'
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'clean-block'
    | 'horizontal-rule'
    | 'preview'
    | 'side-by-side'
    | 'fullscreen'
    | 'guide';

interface Options {
    showIcons?: ReadonlyArray<ToolbarButton>;
}

type ImageUploadType = {
    (image: File,
     onSuccess: (url: string) => void,
     onError: (errorMessage: string) => void): void
}

interface IInputChat {
    userAccess: IHasPermission[]
}

const InputChat: FC<IInputChat> = ({userAccess}) => {
    const {store} = useContext(Context);
    // const [value, setValue] = useState("");
    const [saveValue, setSaveValue] = useState("");
    const [resultValue, setResultValue] = useState("");
    // const [fileMap, setFileMap] = useState<{ [fileId: string]: string | undefined }>({});
    const [fileMap, setFileMap] = useState<{ data: { type: string, id: string, url: string }[] }>({data: []});
    const [edit, setEdit] = useState(false);
    const [idEditMessage, setidEditMessage] = useState(-1);
    const [chatMessage, setChatMessage] = useState<IChatMessage>();
    const [url, setUrl] = useState("");
    const [files, setFiles] = useState<IFilesDisplay | undefined>(undefined);
    const {id} = useParams();
    const containerRef = useRef<HTMLDivElement>(null)

    const [editValue, setEditValue] = useState("");
    const [value, setValue] = useState("");

    const {getData, showToast} = useContext(AppContext);


    const loadFiles = async (filters: IFileTaskFilters) => {
        let req: IGetFilesTaskDisplayData = {
            filters: filters
        }
        const res = await store.getFilesTaskDisplayData(req) as IFilesDisplay;
        setFiles(res);
    };


    const [viewTaskChatMessage, setViewTaskChatMessage] = useState(false);
    const [createTaskChatMessage, setCreateTaskChatMessage] = useState(false);
    const [editTaskChatMessage, setEditTaskChatMessage] = useState(false);
    const [deleteTaskChatMessage, setDeleteTaskChatMessage] = useState(false);

    useEffect(() => {
        try {
            userAccess.forEach((xx) => {
                if (xx.functionCode == "TaskChatAction") { //Информация по задаче
                    xx.permissions.forEach((yy) => {
                        if (yy.permissionCode == "view" && yy.isGranted == true) {
                            setViewTaskChatMessage(true);
                        }
                        if (yy.permissionCode == "create" && yy.isGranted == true) {
                            setCreateTaskChatMessage(true);
                        }
                        if (yy.permissionCode == "edit" && yy.isGranted == true) {
                            setEditTaskChatMessage(true);
                        }
                        if (yy.permissionCode == "delete" && yy.isGranted == true) {
                            setDeleteTaskChatMessage(true);
                        }
                    })
                }
                if (store.user.email == "admin@admin.adm") {
                    setViewTaskChatMessage(true);
                    setCreateTaskChatMessage(true);
                    setDeleteTaskChatMessage(true);
                    setEditTaskChatMessage(true);

                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [userAccess]);

    function getCurentDate(messageDateCur: string) {
        const messageDate = new Date(messageDateCur);
        const today = new Date();
        const yesterday = new Date(today);
        const dayBeforeYesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        dayBeforeYesterday.setDate(today.getDate() - 2);

        if (
            messageDate.getDate() === today.getDate() &&
            messageDate.getMonth() === today.getMonth() &&
            messageDate.getFullYear() === today.getFullYear()
        ) {
            return "Сегодня";
        } else if (
            messageDate.getDate() === yesterday.getDate() &&
            messageDate.getMonth() === yesterday.getMonth() &&
            messageDate.getFullYear() === yesterday.getFullYear()
        ) {
            return "Вчера";
        } else if (
            messageDate.getDate() === dayBeforeYesterday.getDate() &&
            messageDate.getMonth() === dayBeforeYesterday.getMonth() &&
            messageDate.getFullYear() === dayBeforeYesterday.getFullYear()
        ) {
            return "Позавчера";
        } else {
            return `${messageDate.getDate().toString().padStart(2, '0')}.${(messageDate.getMonth() + 1).toString().padStart(2, '0')}.${messageDate.getFullYear()}`;
        }
    }

    function chekDate(): boolean {
        const today = new Date();
        const lastDayInChat = chatMessage?.message[chatMessage?.message?.length - 1]?.messages[chatMessage?.message[chatMessage?.message?.length - 1]?.messages.length - 1];
        const lastDateInChat = new Date(String(lastDayInChat?.createdAt));

        if (
            lastDateInChat.getDate() === today.getDate() &&
            lastDateInChat.getMonth() === today.getMonth() &&
            lastDateInChat.getFullYear() === today.getFullYear()
        ) {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        const boldElement: HTMLButtonElement | null = document.querySelector('.bold');
        if (boldElement) {
            boldElement.title = "Жирный (Ctrl+B)";
        }
        const italicElement: HTMLButtonElement | null = document.querySelector('.italic');
        if (italicElement) {
            italicElement.title = "Курсив (Ctrl+I)";
        }
        const headingElement: HTMLButtonElement | null = document.querySelector('.heading');
        if (headingElement) {
            headingElement.title = "Заголовок (Ctrl+H)";
        }
        const codeElement: HTMLButtonElement | null = document.querySelector('.code');
        if (codeElement) {
            codeElement.title = "Код (Ctrl+Alt+C)";
        }
        const quoteElement: HTMLButtonElement | null = document.querySelector('.quote');
        if (quoteElement) {
            quoteElement.title = "Кавычки (Ctrl+')";
        }
        const unorderedListElement: HTMLButtonElement | null = document.querySelector('.unordered-list');
        if (unorderedListElement) {
            unorderedListElement.title = "Неупорядоченный список (Ctrl+B)";
        }
        const orderedListElement: HTMLButtonElement | null = document.querySelector('.ordered-list');
        if (orderedListElement) {
            orderedListElement.title = "Упорядоченный список (Ctrl+Alt+L)";
        }
        const horizontalRuleElement: HTMLButtonElement | null = document.querySelector('.horizontal-rule');
        if (horizontalRuleElement) {
            horizontalRuleElement.title = "Горизонтальная линия";
        }
        const undoElement: HTMLButtonElement | null = document.querySelector('.undo');
        if (undoElement) {
            undoElement.title = "Назад (Ctrl+Z)";
        }
        const redoElement: HTMLButtonElement | null = document.querySelector('.redo');
        if (redoElement) {
            redoElement.title = "Вперед (Ctrl+Y)";
        }
    }, [userAccess]);

    const saveEditMessage = () => {
        (async () => {
            await store.saveEditMessage(editValue, idEditMessage);
            let data = {data: [{}]}
            setFileMap({ data: [] })
            setValue("");
            await loadChatMessage();
            setEdit(false);
            setEditValue("");
            setidEditMessage(-1);
        })()
    }

    function deleteMessage(idMessage: number) {
        if (deleteTaskChatMessage) {
            (async () => {
                await store.deleteMessage(idMessage);
                await loadChatMessage();
            })()
        } else {
            showToast('У вас нет прав на удаление комментариев')
        }
    }

    const generateFileId = (fileType: string) => {
        const id = Date.now().toString(); //.slice(0,5);
        return `${fileType}${id}`;
    }

    //Все что выше можно не трогать

    // useEffect(() => {
    //     replaceHtml(value);
    // }, [fileMap, value, id]);

    //Обработка файлов
    function fileIcon(fileType: string | undefined) {
        let fileTypeClean = fileType?.replace(/\./g, '');
        let variableName = `${fileTypeClean}_svg`;
        let pictureSrc = (fileIcons as any)[variableName];
        if (!pictureSrc) {
            //Тут если не нашли картинку нужна женерик картинка файла
            return unknown_svg;
        } else {
            return pictureSrc;
        }

    }

    const imageUpload = async (file: any, onSuccess: any, onError: any) => {
        try {
            const url = await store.addFileChat(file, Number(id), 1);

            if (url?.status != 400) {
                store.setUpdateFile(true);
                let fileType = 'F';

                if (file.type.includes("image")) {
                    fileType = 'G';
                } else if (file.type.includes("audio")) {
                    fileType = 'M';
                } else if (file.type.includes("video")) {
                    fileType = 'V';
                }

                let fileTypeGet = file.name.split(".");
                let imgGet = fileIcon(fileTypeGet[fileTypeGet.length - 1]);
                let fileName = file.name.length > 10 ? file.name.substring(0, 10) + "..." : file.name;

                let dataSave = fileType === 'G' ? `<img src="${url}" />` :
                    fileType === 'M' ? `<audio controls className="input--chat__block--messages__message--audio__chat--block" src="${url}">${fileName}</audio>` :
                    fileType === 'V' ? '<video style="padding-top: 10px" width="500px" height="240px" controls="controls"><source type="video/mp4" src="' + url + '"/></video>' :
                        `<div className="input--chat__block--messages__message--file__chat--block"><div className="input--chat__block--messages__message--file__chat"><img src="${imgGet}"/><a href="${url}">${fileName}</a></div></div>`

                const fileId = generateFileId(fileType);

                const newFileMap: { type: string, id: string, url: string } = {
                    type: fileType,
                    id: "{" + fileId + "}",
                    url: dataSave
                };

                setFileMap(prevFileMap => ({
                    data: [...prevFileMap.data, newFileMap]
                }));

                setEditValue(prevEditValue => prevEditValue + ` ${dataSave}`);

                setValue(oldValue => oldValue + ` {${fileId}}`);
                showToast(`Файл ${"{" + fileId + "}"} успешно загружен!`)
            } else {
                showToast(url?.data?.message)
            }
        } catch (error) {
            onError("Ошибка загрузки изображения");
        }
    };

    function chekText(newValue: string) {
        let changeValue = newValue;
        console.log("changeValue", [changeValue])
        let newFileMap: { type: string, id: string, url: string }[] = fileMap?.data;

        let newMass: string[] = [];

        let prevIndex = 0;
        let checkCode = false;
        for (let i = 0; i <= changeValue.length; i++) {
            if (changeValue[i-2] === '`' && changeValue[i-1] === '`' && changeValue[i] === '`') {
                if (checkCode == false) {
                    checkCode = true;
                } else {
                    newMass.push(changeValue.substring(prevIndex, i+1));
                    prevIndex = i + 1;
                    checkCode = false;
                }
            }

            if (checkCode == false) {
                if (changeValue[i] === '\n' && newMass[newMass.length] === '\n') {
                    newMass[newMass.length] = newMass[newMass.length - 1];
                } else if (changeValue[i] === '\n') {
                    newMass.push(changeValue.substring(prevIndex, i+1));
                    prevIndex = i + 1;
                } else if (changeValue[i] === ' ') {
                    newMass.push(changeValue.substring(prevIndex, i));
                    prevIndex = i + 1;
                } else if (i == changeValue.length) {
                    newMass.push(changeValue.substring(prevIndex, i));
                    prevIndex = i + 1;
                }
            }
        }

        newMass = newMass.filter(xx => xx.length > 0);

        newMass = newMass?.map(value => {
            if (value.slice(0,5).includes("https") || value.slice(0,5).includes("http") && !value.slice(0,5).includes("<a")) {
                let valueSave = value;
                let newValue = ""
                if (value?.length > 80) {
                    while (value?.length > 80) {
                        newValue += value.slice(0, 80) + "&shy";
                        value = value.substring(80, value?.length) + "&shy";
                    }
                    newValue += value
                }
                if (value.slice(value.length-1, value.length).includes('\n')) {
                    value = `<a href="${value}">${newValue?.length > 0 ? newValue : valueSave}</a>` + value.slice(value.length-1, value.length);
                } else {
                    value = `<a href="${value}">${newValue?.length > 0 ? newValue : valueSave}</a>`;
                }
                return value;
            }
            return value;
        })

        changeValue = ""

        console.log("newMass.length", newMass);

        for (let i = 0; i < newMass.length; i++) {
            console.log("-------------", i);
            // console.log("length", newMass[i].length);
            // console.log(newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n")
            // console.log(newMass[i+1]?.slice(newMass[i+1]?.length-1,newMass[i+1]?.length) == "\n")

            // if (newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n" &&
            //     newMass[i+1]?.slice(newMass[i+1]?.length-1,newMass[i+1]?.length) == "\n" &&
            //     newMass[i+2]?.slice(newMass[i+2]?.length-1,newMass[i+2]?.length) == "\n") {
            //     changeValue += newMass[i] + "</br>"
            // } else if (newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n" &&
            //     newMass[i+1]?.slice(newMass[i+1]?.length-1,newMass[i+1]?.length) == "\n" &&
            //     newMass[i+2]?.slice(newMass[i+2]?.length-1,newMass[i+2]?.length) != "\n") {
            //
            //     console.log(newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n", 1)
            //     console.log(newMass[i+1]?.slice(newMass[i+1]?.length-1,newMass[i+1]?.length) == "\n", 2);
            //     console.log(newMass[i+2]?.slice(newMass[i+2]?.length-1,newMass[i+2]?.length) != "\n", 3);
            //     console.log([newMass[i]], [newMass[i+1]], [newMass[i+2]])
            //     changeValue += newMass[i] + "</br></br>"
            // } else if (newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n") {
            //     changeValue += newMass[i] + "</br>"
            // } else {
            //     changeValue += newMass[i] + " "
            // }

            if (newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n" &&
                newMass[i+1] == "\n" &&
                newMass[i+2] == "\n") {
                    changeValue += newMass[i] + "</br>"
            } else if (newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n" &&
                newMass[i+1] == "\n" &&
                newMass[i+2] != "\n") {

                console.log(newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n", 1)
                console.log(newMass[i+1]?.slice(newMass[i+1]?.length-1,newMass[i+1]?.length) == "\n", 2);
                console.log(newMass[i+2]?.slice(newMass[i+2]?.length-1,newMass[i+2]?.length) != "\n", 3);
                console.log([newMass[i]], [newMass[i+1]], [newMass[i+2]])
                changeValue += newMass[i] + "</br>"
            } else if (newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n") {
                changeValue += newMass[i] + "</br>"
            } else {
                changeValue += newMass[i] + " "
            }
        }
    // .slice(newMass[i].length-1,newMass[i].length)

        // let newString = changeValue.replaceAll('\n', '*');
        // console.log("newString",newString)
        //
        // // changeValue = newMass.join(" ");
        //
        // console.log("newMass 3",newMass)
        // console.log("changeValue 3",changeValue)

        fileMap.data.forEach(current => {
            if (current.type != "H") {
                const startIndex = changeValue.indexOf(current.id);
                const endIndex = current.id.length + startIndex;
                if (startIndex !== -1) {
                    changeValue = changeValue.slice(0,startIndex) + current.url + changeValue.slice(endIndex, changeValue.length);
                }
            }
        });

        setFileMap(prevFileMap => ({
            data: newFileMap
        }));

        console.log("changeValue 6", [changeValue])

        setEditValue(changeValue)
    }

    const onChange = (newValue: string) => {
        chekText(newValue);
        setValue(newValue);

    };

    const closeEditMessage = () => {
        setValue("");
        setEditValue("");
        setEdit(false);
        setFileMap({ data: [] });
        setidEditMessage(-1);
    }


    const saveMessage = () => {
        if (createTaskChatMessage) {
            (async () => {
                await store.sendMessage(editValue, Number(id));
                setValue("");
                setEditValue("");
                setEdit(false);
                setFileMap({ data: [] });
                await loadChatMessage();
            })();
        } else {
            showToast('У вас нет прав для отправки сообщений')
        }
    };

    function formatDate(message: string) {
        const date = new Date(message);
        return format(date, 'MMM dd, yyyy');
    }

    function formatEditMessage(text: string) {
        let changeValue = text;
        let newFileMap: { type: string, id: string, url: string }[] = fileMap?.data;

        let newMass: string[] = [];

        let prevIndex = 0;
        let checkCode = false;
        for (let i = 0; i <= changeValue.length; i++) {
            if (changeValue[i-2] === '`' && changeValue[i-1] === '`' && changeValue[i] === '`') {
                if (checkCode == false) {
                    checkCode = true;
                } else {
                    newMass.push(changeValue.substring(prevIndex, i+1));
                    prevIndex = i + 1;
                    checkCode = false;
                }
            }

            if (checkCode == false) {
                if (changeValue[i] === '\n' && newMass[newMass.length] === '\n') {
                    newMass[newMass.length] = newMass[newMass.length - 1];
                } else if (changeValue[i] === '\n') {
                    newMass.push(changeValue.substring(prevIndex, i+1));
                    prevIndex = i + 1;
                } else if (changeValue[i] === ' ') {
                    newMass.push(changeValue.substring(prevIndex, i));
                    prevIndex = i + 1;
                } else if (i == changeValue.length) {
                    newMass.push(changeValue.substring(prevIndex, i));
                    prevIndex = i + 1;
                }
            }
        }

        newMass = newMass.filter(xx => xx.length > 0);

        for (let i = 0; i < newMass.length; i++ ) {
            if (newMass[i].includes("</a></div></div>")) {
                let j = i-1;
                while (!newMass[i].includes("href=") && j >= 0) {
                    newMass[i] = newMass[j] + " " + newMass[i];
                    newMass[j] = '';
                    j--;
                }
            }
        }

        newMass = newMass.map(value => {

            if (value == '<a' || value == '<a\n' || value == '<img'  || value == '<img\n' || value == '/>' || value == '<div' ||
                value.includes('href=\"[object') || value == '/>\n' || value == '<div\n' ||
                value.includes('input--chat__block--messages__message--file__chat') || value == "<audio" || value == "controls" ||
                value.includes('className="input--chat__block--messages__message--audio__chat--block"') || value == "<video" ||
                value.includes('style="padding-top:') || value.includes('10px"') || value.includes('width="500px"') ||
                value.includes('height="240px"') || value.includes('controls="controls"><source') || value.includes('type="video/mp4"')) {
                value = '';
                return value;
            }

            if (value.slice(value.length-8, value.length).includes('</video>') || value.slice(value.length-9, value.length).includes('</video>\n')) {
                value = value.slice(5, value.length-11);
                let fileType = 'V';

                const fileId = generateFileId(fileType);

                const newFileMap: { type: string, id: string, url: string } = {
                    type: fileType,
                    id: "{" + fileId + "}",
                    url: `<video style="padding-top: 10px" width="500px" height="240px" controls="controls"><source type="video/mp4" src="` + value + `"/></video>`
                };

                setFileMap(prevFileMap => ({
                    data: [...prevFileMap.data, newFileMap]
                }));

                value = "{" + fileId + "}";



                return value;
            }

            if (value.slice(value.length-8, value.length).includes("</audio>")) {
                value = value.slice(5, value.length-8);
                let nameFile = value.split(">")[1];
                value = value.split('"')[0];

                let fileType = 'M';

                const fileId = generateFileId(fileType);

                const newFileMap: { type: string, id: string, url: string } = {
                    type: fileType,
                    id: "{" + fileId + "}",
                    url: `<audio controls className="input--chat__block--messages__message--audio__chat--block" src="${url}">${nameFile}</audio>`
                };

                setFileMap(prevFileMap => ({
                    data: [...prevFileMap.data, newFileMap]
                }));

                value = "{" + fileId + "}";

                return value;
            }

            if (value.slice(value.length-6, value.length).includes('\"/><a')) {
                value = '';
                return value;
            }

            if (value.slice(value.length - 13, value.length).includes('</div></div>')) {
                value = value.slice(6, value.length);
                let nameFile = value.slice(1, value.length).split("<")[0].split(">")[1];

                value = value.split('"')[0];

                let fileType = 'F';

                let fileTypeGet = nameFile.split(".");
                let imgGet = fileIcon(fileTypeGet[fileTypeGet.length - 1]);
                const fileId = generateFileId(fileType);

                const newFileMap: { type: string, id: string, url: string } = {
                    type: fileType,
                    id: "{" + fileId + "}",
                    url: `<div className="input--chat__block--messages__message--file__chat--block"><div className="input--chat__block--messages__message--file__chat"><img src="${imgGet}"/><a href="${value}">${nameFile}</a></div></div>`
                };

                setFileMap(prevFileMap => ({
                    data: [...prevFileMap.data, newFileMap]
                }));

                value = "{" + fileId + "}";

                return value;
            }

            if (value.slice(0,6).includes("href=")) {
                value = value.slice(6,value.length-4);
                value = value.split('"')[0];
                return value;
            }

            if (value.slice(0,4).includes("src=")) {
                value = value.slice(5,value.length-1);

                let fileType = 'G';

                const fileId = generateFileId(fileType);
                const newFileMap: { type: string, id: string, url: string } = {
                    type: fileType,
                    id: "{" + fileId + "}",
                    url: `<img src="${value}" />`
                };

                setFileMap(prevFileMap => ({
                    data: [...prevFileMap.data, newFileMap]
                }));

                value = "{" + fileId + "}";

                return value;
            }

            return value;
        })

        newMass = newMass.filter(xx => xx != '');
        // changeValue = newMass.join(" ");
        changeValue = ""
        console.log("newMass", newMass)

        for (let i = 0; i < newMass.length; i++) {
            console.log("-------------", i);
            console.log("length", newMass[i].length);
            console.log(newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n")
            console.log(newMass[i+1]?.slice(newMass[i+1]?.length-1,newMass[i+1]?.length) == "\n")

            if (newMass[i].includes("</br>")) {
                let newMassSplit = newMass[i].split("</br>").filter(x => x !== "");
                newMassSplit.forEach(yy => {
                    changeValue += yy
                })
                changeValue += " "
                // console.log("newMassSplit", newMassSplit)
            } else {
                changeValue += newMass[i] + " "
            }

            // if (newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n" &&
            //     newMass[i+1]?.slice(newMass[i+1]?.length-1,newMass[i+1]?.length) == "\n" &&
            //     newMass[i+2]?.slice(newMass[i+2]?.length-1,newMass[i+2]?.length) == "\n") {
            //     changeValue += newMass[i] + "</br>"
            // } else if (newMass[i].slice(newMass[i].length-1,newMass[i].length) == "\n" &&
            //     newMass[i+1]?.slice(newMass[i+1]?.length-1,newMass[i+1]?.length) == "\n" &&
            //     newMass[i+2]?.slice(newMass[i+2]?.length-1,newMass[i+2]?.length) != "\n") {
            //     changeValue += newMass[i] + "</br></br>"
            // } else {
            //     changeValue += newMass[i] + " "
            // }
        }

        setValue(changeValue);
    }

    function editMessage(idMessage: number, date: string) {
        if (deleteTaskChatMessage) {
            const dateForm = new Date(date);
            const curentDateString = format(date, 'yyyy-MM-dd');
            const message = chatMessage?.message.find((xx) => xx.date == curentDateString)!.messages.find((yy) => yy.id == idMessage);
            setEdit(true);
            setidEditMessage(idMessage);
            setEditValue(message!.text);
            formatEditMessage(message!.text)
        } else {
            showToast('У вас нет прав на редактирование комментариев')
        }
    }

    const newOptions = useMemo(() => {
        return {
            spellChecker: false,
            hideIcons: [ 'link'
                , 'image'
                , 'strikethrough'
                , 'table'
                , 'heading-bigger'
                , 'heading-smaller'
                , 'heading-1'
                , 'heading-2'
                , 'heading-3'
                , 'clean-block'
                , 'preview'
                , 'side-by-side'
                , 'fullscreen'
                , 'guide'],
            showIcons: ['code'
                ,'bold'
                , 'italic'
                ,'quote'
                , 'unordered-list'
                , 'ordered-list'
                , 'code'
                , 'redo'
                , 'undo'
                , 'heading'
                , 'horizontal-rule'
            ],
            uploadImage: true,
            imageUploadFunction: imageUpload,
            placeholder: "Введите текст...",
        } as Options;
    }, []);

    const loadChatMessage = async () => {
        let res = await store.getChatMessage(Number(id))
        if (res?.message) {
            res.message = res.message.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        }
        setChatMessage(res!);
    }

    useEffect(() => {
        (async () => {
            try {
                await loadChatMessage()
            } catch (error) {
                console.log(error)
            }
        })();
    }, [id]);

    return (
        <div className="input--chat__block">
            <div className="input--chat__block--messages">
                {viewTaskChatMessage && chatMessage?.message?.map((message, index) => (
                    <div>
                        <div key={index + "date"} className="input--chat__block--messages__date">
                            <div className="input--chat__block--messages__date--line"></div>
                            <div className="input--chat__block--messages__date--num">
                                {getCurentDate(message?.date)}
                            </div>
                            <div className="input--chat__block--messages__date--line"></div>
                        </div>
                        {message?.messages?.map((curentMessages) => (
                            <div className={
                                store.user.surname + " " + store.user.name === curentMessages.createdBy
                                    ? "input--chat__block--messages__message--block__right"
                                    : "input--chat__block--messages__message--block__left"
                            }
                            >
                                <div className={
                                    store.user.surname + " " + store.user.name === curentMessages.createdBy
                                        ? "input--chat__block--messages__message--block__flex--right"
                                        : "input--chat__block--messages__message--block__flex--left"
                                }
                                     style={edit && curentMessages.id == idEditMessage ? {backgroundColor: 'lightblue'} : {}}
                                >
                                    <div className="input--chat__block--messages__message--img">
                                        <img src={UserPicture}/>
                                    </div>
                                    <div key={curentMessages.id} className="input--chat__block--messages__message">
                                        <div className="input--chat__block--messages__message--all">
                                            <div className="input--chat__block--messages__message--author">
                                                {curentMessages.createdBy}
                                            </div>
                                            <div className="input--chat__block--messages__message--role">
                                                {curentMessages.role}
                                            </div>
                                            <div className="input--chat__block--messages__message--date">
                                                {formatDate(curentMessages.createdAt)}
                                            </div>
                                            {
                                                curentMessages.updatedAt == curentMessages.createdAt ? "" :
                                                    <a className="input--chat__block--messages__message--update">Изменено</a>
                                            }
                                        </div>
                                        <div className="input--chat__block--messages__message--text">
                                            <MarkdownPreview content={edit && curentMessages.id == idEditMessage ? editValue : curentMessages.text}/>
                                        </div>
                                        {store.user.surname + " " + store.user.name === curentMessages.createdBy ?
                                            <div className="input--chat__block--messages__message--buttons">
                                                <button
                                                    onClick={() => editTaskChatMessage ? editMessage(curentMessages.id, curentMessages.createdAt) : showToast("У вас нет прав на редактирование!")}>Редактировать
                                                </button>
                                                <button onClick={() => deleteTaskChatMessage ? deleteMessage(curentMessages.id) : showToast("У вас нет прав на удаление!")}>Удалить
                                                </button>
                                            </div>
                                        : ""}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {value && !edit ?
                <div>
                    {chekDate() ?
                        <div className="input--chat__block--messages__date">
                            <div className="input--chat__block--messages__date--line"></div>
                            <div className="input--chat__block--messages__date--num">
                                Сегодня
                            </div>
                            <div className="input--chat__block--messages__date--line"></div>
                        </div>
                        : ""}
                    <div className="input--chat__block--messages__message--block__right">
                        <div className="input--chat__block--messages__message--block__flex--right">
                            <div className="input--chat__block--messages__message--img">
                                <img src={UserPicture}/>
                            </div>
                            <div className="input--chat__block--messages__message">
                                <div className="input--chat__block--messages__message--all">
                                    <div className="input--chat__block--messages__message--author">
                                        {store.user.surname + " " + store.user.name}
                                    </div>
                                    <div className="input--chat__block--messages__message--date">
                                        {formatDate(String(new Date()))}
                                    </div>
                                </div>
                                <div className="input--chat__block--messages__message--text">
                                    <MarkdownPreview content={editValue}/>
                                    {/*<MarkdownPreview content={value}/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ""}
            <div className="input--input__block">
                <div className="input--input__block--buttons">
                    <SimpleMDEditor id="editor" value={value} onChange={onChange} options={newOptions}/>
                    <div className="input--input__block--buttons__send">
                        <div className="input--input__block--buttons__send--div">
                            {edit ?
                                <div>
                                    <button className="input--input__block--buttons__send--div__cansel"
                                            onClick={closeEditMessage}>
                                        Отмена
                                    </button>
                                    <button className="input--input__block--buttons__send--div__approve"
                                            onClick={saveEditMessage}>
                                        Сохранить
                                    </button>
                                </div>
                                :
                                <button
                                    className={createTaskChatMessage ? "input--input__block--buttons__send--div__approve" : "input--input__block--buttons__send--div__approve-block"}
                                    onClick={saveMessage}>
                                    Отправить
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InputChat;