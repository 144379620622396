import { FC, useContext, useEffect, useState } from "react";
import { IProjectHistoryDisplay } from "../../../models/ProjectModels";
import HistoryTable from "../../Shared/HistoryTable/HistoryTable";
import BtnFilter from "../../buttons/btnFilter.component/BtnFilter";
import CancelButton from "../../buttons/CancelButton/CancelButton";
import { IFilter } from "../../../models/response/IFilterResponse";
import { Context } from "../../..";
import { initials } from "../../../helpers/Inicials";
import { ITaskHistoryFilter } from "../../../models/TaskModels";
import { useParams } from "react-router-dom";
import "./TaskHistory.css";

interface TaskHistoryProps {
    records: IProjectHistoryDisplay[];
    onChangeFilters: (filters: ITaskHistoryFilter) => void;
}

const TaskHistory: FC<TaskHistoryProps> = ({ records, onChangeFilters }) => {
    const { id } = useParams();
    const { store } = useContext(Context);

    const [searchValue, setSearchValue] = useState<string>("");
    const [filterCounter, setFilterCounter] = useState<number>(0);
    const [filterData, setFilterData] = useState<IFilter[]>([]);
    const [resetFilterData, setResetFilterData] = useState<IFilter[]>([]);
    const [checkedFilters, setCheckedFilters] = useState<any>({
        responsible: undefined,
        date: undefined,
    });
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [dates, setDates] = useState(null);

    useEffect(() => {
        (async () => {
            if (!store.currentProjectId) return;
            let data = await store.getDataForFilter({
                projectId: Number(store.currentProjectId),
                date: true,
                responsible: true,
                status: false,
                typeTask: false,
                priority: false,
                role: false,
                fileType: false,
            });
            if (!data) return;
            //Преобразования Имя пользователя к формату ФИО
            let a: any = data?.map((i) => {
                if (i.type && i.type === "users") {
                    let c: any = i.items?.map((x: any) => {
                        return { code: x.code, name: initials(x.name!) };
                    });
                    if (c?.length) {
                        i.items = c;
                        return i;
                    } else {
                        return i;
                    }
                } else {
                    return i;
                }
            });

            let resetData = structuredClone(a);
            setFilterData(a);
            setResetFilterData(resetData);
        })();
    }, [store.currentProjectId]);

    const onCancelClick = async () => {
        resetFilterData && setFilterData(structuredClone(resetFilterData));
        let nullFilters = {
            projectId: Number(id),
        };
        setCheckedFilters({ ...nullFilters });
        setSearchValue("");
        setSelectedUsers(null);
        setDates(null);
        setFilterCounter(0);

        parseFilters(nullFilters);
    };

    const onSearchSubmit = async (value: string) => {
        let newFilters = { ...checkedFilters };
        newFilters.name = value;
        setCheckedFilters({ ...newFilters });
        parseFilters(newFilters);
    };

    const applyFilters = async () => {
        parseFilters(checkedFilters);
    };

    const parseFilters = async (appliedFilters: any) => {
        const filters: ITaskHistoryFilter = {
            taskId: Number(id),
            dateFrom:
                (appliedFilters.date && appliedFilters.date[0]) ?? undefined,
            dateTo:
                (appliedFilters.date && appliedFilters.date[1]) ?? undefined,
            userIds: appliedFilters.responsible ?? undefined,
        };
        onChangeFilters(filters);
    };

    return (
        <div>
            <div className="task-history-filters">
                <BtnFilter
                    filterData={filterData}
                    setFilterData={setFilterData}
                    applayFilters={applyFilters}
                    checkedFilters={checkedFilters}
                    setCheckedFilters={setCheckedFilters}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    dates={dates}
                    setDates={setDates}
                    counter={filterCounter}
                    setCounter={setFilterCounter}
                    showApplyButton={true}
                />
                {(filterCounter !== 0 || searchValue) && (
                    <CancelButton onClickFunc={onCancelClick} />
                )}
            </div>
            <HistoryTable records={records} onScrolledToBottom={() => {}} showHyperlinkColumn={false} />
        </div>
    );
};

export default TaskHistory;
