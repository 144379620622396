import {
    createContext,
    Dispatch,
    FC,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { Context } from "../../index";
import "../../globalstyles/page.pc.css";
import "../../component/styles/dashboard.pc.css";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import BoardColumns from "../../component/Board/BoardColumns/BoardColumns";
import { IColumnDisplay } from "../../models/IColumnDisplay";
import BoardToolbar from "../../component/Board/BoardToolbar/BoardToolbar";
import ConfirmationWindow from "../../component/Shared/ConfirmationWindow/ConfirmationWindow";
import BoardMenu from "../../component/Board/BoardMenu/BoardMenu";
import { IFilterData, ITasksDisplayFilters } from "../../models/TaskModels";
import { IColumnEdit } from "../../models/IColumnEdit";
import { IMakeColumnProxy } from "../../models/IMakeColumnProxy";
import { IMakeColumnCommon } from "../../models/IMakeColumnCommon";
import { AppContext } from "../../App";
import { IMoveColumn } from "../../models/IMoveColumn";
import {
    IFilter,
    IFilterItem,
    IFilterUser,
} from "../../models/response/IFilterResponse";
import { IIParametrsFilter } from "../../models/IFilterModel";
import { IAddColumn } from "../../models/IAddColumn";
import { IBoardTaskDisplay } from "../../models/IBoardTaskDisplay";
import { initials } from "../../helpers/Inicials";
import TaskAddEditForm from "../../component/Task/TaskAddEditForm/TaskAddEditForm";
import FunctionSystem from "../../models/functionCode/functionsSystem";
import { ICheckRole, IHasPermission } from "../../models/IChekRole";
import { ITeamMemberDisplay } from "../../models/TeamModels";
import { IMoveTasksData } from "../../models/IMoveTaskData";

export interface ISelectedFastFilters {
    priority: IFilterData | undefined;
    type: IFilterData | undefined;
    tag: IFilterData | undefined;
}

interface ISelectedBoardFilters {
    priority: IFilterData[];
    type: IFilterData[];
}

export interface IBoardForProxy {
    id: number;
    name: string;
}

interface BoardContextType {
    boardIdNumber: number;
    handleDeleteTask: (taskId: number) => void;
    handleDeleteTasks: (taskIds: number[]) => void;
    handleSelectTask: (taskId: number) => void;
    handleMoveTask: (
        taskId: number,
        newColumnId: number,
        position: number
    ) => void;
    handleMoveTaskBoard: (taskId: number, boardId: number) => void;
    handleMoveTasks: (columnId: number) => void;
    handleMoveColumn: (columnId: number, position: number) => void;
    columns: IColumnDisplay[];
    filteredColumns: IColumnDisplay[];
    taskNameSearch: string;
    setTaskNameSearch: Dispatch<SetStateAction<string>>;
    taskFilters: ITasksDisplayFilters;
    selectedFastFilters: ISelectedFastFilters;
    changeFastFilters: (filters: ISelectedFastFilters) => void;
    actionMode: boolean;
    cancelActionMode: () => void;
    selectedTasks: number[];
    relatedUsers: ITeamMemberDisplay[];
    openMenu: () => void;
    closeMenu: () => void;
    boardName: string;
    handleDeleteColumn: (columnId: number) => void;
    handleEditColumn: (
        columndId: number,
        newName?: string,
        newColor?: string,
        newWeight?: number
    ) => void;
    openAddTaskForm: (columnId: number) => void;
    handleRenameBoard: (newName: string) => void;
    handleDeleteBoard: () => void;
    handleAddColumn: (
        name: string,
        color: string,
        weight: number,
        columnStatus: number | undefined,
        columnPriority: number | undefined,
        proxyBoardName?: string,
        proxiBoardId?: number
    ) => void;
    // Доски, отличающиеся от текущей
    boardsForProxy: IBoardForProxy[];
    handleMakeColumnCommon: (columnId: number) => void;
    handleMakeColumnProxy: (
        columnId: number,
        proxyBoardName?: string,
        proxyBoardId?: number
    ) => void;
    colorOptions: string[];
    boardFilters: IFilter[];
    setBoardFilters: Dispatch<SetStateAction<IFilter[]>>;
    checkedBoardFilters: any;
    setCheckedBoardFilters: Dispatch<any>;
    datesFilter: any;
    setDatesFilter: Dispatch<SetStateAction<any>>;
    usersFilter: any;
    setUsersFilter: Dispatch<SetStateAction<any>>;
    filterCounter: number;
    setFilterCounter: Dispatch<SetStateAction<number>>;
    handleTaskAction: () => void;
    resetFilter: () => void;
    projectId: number | undefined;
    isProxyBoard: boolean;
    abbreviationProject: string | undefined
}

export const BoardContext = createContext<BoardContextType>({
    boardIdNumber: -1,
    handleDeleteTask: () => {
    },
    handleDeleteTasks: () => {
    },
    handleSelectTask: () => {
    },
    handleMoveTask: () => {
    },
    handleMoveTaskBoard() {
    },
    handleMoveTasks: () => {
    },
    handleMoveColumn: () => {
    },
    columns: [],
    filteredColumns: [],
    taskNameSearch: "",
    setTaskNameSearch: () => {
    },
    taskFilters: { priority: [], type: [], status: [], tag: [] },
    selectedFastFilters: { priority: undefined, type: undefined, tag: undefined },
    changeFastFilters: () => {
    },
    actionMode: false,
    cancelActionMode: () => {
    },
    selectedTasks: [],
    relatedUsers: [],
    openMenu: () => {
    },
    closeMenu: () => {
    },
    boardName: "",
    handleDeleteColumn: () => {
    },
    handleEditColumn: () => {
    },
    openAddTaskForm: () => {
    },
    handleRenameBoard: () => {
    },
    handleDeleteBoard: () => {
    },
    handleAddColumn: () => {
    },
    boardsForProxy: [],
    handleMakeColumnCommon: () => {
    },
    handleMakeColumnProxy: () => {
    },
    colorOptions: [],
    boardFilters: [],
    setBoardFilters: () => {
    },
    checkedBoardFilters: {},
    setCheckedBoardFilters: () => {
    },
    datesFilter: undefined,
    setDatesFilter: () => {
    },
    usersFilter: undefined,
    setUsersFilter: () => {
    },
    filterCounter: 0,
    setFilterCounter: () => {
    },
    handleTaskAction: () => {
    },
    resetFilter: () => {
    },
    projectId: -1,
    isProxyBoard: false,
    abbreviationProject: '-'
});

const Board: FC = () => {
    const { store } = useContext(Context);
    const { showToast } = useContext(AppContext);
    const { boardId } = useParams();
    const [columns, setColumns] = useState<IColumnDisplay[]>([]);
    const [filteredColumns, setFilteredColumns] = useState<IColumnDisplay[]>(
        []
    );
    const boardIdNumber = Number(boardId);
    const [actionMode, setActionMode] = useState<boolean>(false);
    const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
    const [taskNameSearch, setTaskNameSearch] = useState<string>("");
    const [taskFilters, setTaskFilters] = useState<ITasksDisplayFilters>({
        priority: [],
        type: [],
        status: [],
        tag: []
    });
    const [selectedFastFilters, setSelectedFastFilters] =
        useState<ISelectedFastFilters>({
            priority: undefined,
            type: undefined,
            tag: undefined
        });
    const [boardFilters, setBoardFilters] = useState<IFilter[]>([]);
    const [boardResetFilters, setBoardResetFilters] = useState<IFilter[]>([]);
    const [checkedBoardFilters, setCheckedBoardFilters] = useState<any>({
        priority: undefined,
        typeTask: undefined,
    });
    const [datesFilter, setDatesFilter] = useState<any>(null);
    const [usersFilter, setUsersFilter] = useState<any>(null);
    const [filterCounter, setFilterCounter] = useState<number>(0);
    const [forceFilterChange, setForceFilterChange] = useState<boolean>(false);
    const [tasksToDelete, setTasksToDelete] = useState<number[]>([]);
    const [columnToDelete, setColumnToDelete] = useState<IColumnDisplay>();
    const [boardsForProxy, setBoardsForProxy] = useState<IBoardForProxy[]>([]);
    const [showConfirmDeleteTasks, setShowConfirmDeleteTasks] =
        useState<boolean>(false);
    const [showConfirmDeleteBoard, setShowConfirmDeleteBoard] =
        useState<boolean>(false);
    const [showConfirmDeleteColumn, setShowConfirmDeleteColumn] =
        useState<boolean>(false);
    const [relatedUsers, setRelatedUsers] = useState<ITeamMemberDisplay[]>([]);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [boardName, setBoardName] = useState<string>("Название доски");
    const [showTaskPopup, setShowTaskPopup] = useState(false);
    const [addTaskColumnId, setAddTaskColumnId] = useState<number | undefined>();
    const [rerenderBoard, setRerenderBoard] = useState<boolean>(false);
    const [userAccess, setUserAccess] = useState<IHasPermission[]>([]);

    const [projectId, setProjectId] = useState<number | undefined>(store.currentProjectId);
    const [isProxyBoard, setIsProxyBoard] = useState<boolean>(false);

    const [abbreviationProject, setAbbreviationProject] = useState<string>();


    const colorOptions: string[] = [
        "rgba(20,156,206,0.2)",
        "#C7DAF0",
        "#C2CBF8",
        "#E4D4F8",
        "#FACEF3",
        "#FADBE1",
        "#FCF8D6",
        "#D7F6D4",
        "#EAEAEA",
        "#AFAFAF",
    ];

    const navigate = useNavigate();

    const { getData } = useContext(AppContext);

    //Ограничение доступа
    useEffect(() => {
        (async () => {
            try {
                let projectId = await store.getProjectIdDashBoards(Number(boardId));
                if (projectId != 0 && projectId != undefined) {
                    store.setCurrentProjectId(projectId)
                    const functionClass = new FunctionSystem()
                    const newCheckRole: ICheckRole = {
                        projectId: projectId,
                        boardId: Number(boardId),
                        functionCodes: functionClass.getProjectBoardFunction
                    };

                    const checkThisRole = await store.hasPermission(newCheckRole)

                    setUserAccess(checkThisRole);
                }
            } catch (error) {
                console.log(error)
            }
        })();
    }, [store.currentProjectId, boardId, rerenderBoard]);

    useEffect(() => {
        (async () => {
            try {
                setShowMenu(false);

                if (isNaN(store.currentProjectId)) {
                    console.log("Не найден id проекта!");
                    return;
                }
                await store.getAllColumnsBoard(
                    Number(boardId)
                );
                setColumns(store.columns);
                setBoardName(store.currentBoardData.label);
                setProjectId(store.currentProjectId);
                // setIsProxyBoard(store.dashBoards.find((b) => b.id === Number(boardId))!.isProxyBoard)
                let res = await store.getProjectGeneralInfo(store.currentProjectId);
                if (res) {
                    setAbbreviationProject(res.abbreviation)
                }

                const boardData = await store.restrictAccess(store.currentProjectId);

                if (boardData == undefined) {
                    console.log("Нет доступа к доске!");
                    return;
                }

                const personsRolesIds: number[] | undefined = boardData?.person.filter(xx => xx.email == store.user.email)?.map(yy => yy.personRoleId);

                if (personsRolesIds == undefined) {
                    console.log("Не найдены пользователи!");
                    return;
                }

                console.log("personsRolesIds", personsRolesIds)
                console.log("boardData.boards", boardData.boards)

                const checkBoard = boardData.boards.filter(xx => {
                    return xx.personRoleIds?.some(personRoleId => personsRolesIds.includes(personRoleId));
                });

                console.log("checkBoard", checkBoard)

                if (!checkBoard.map(xx => xx.id).includes(Number(boardId))) {

                    await store.getAllColumnsBoard(
                        Number(boardId)
                    );
                    setColumns(store.columns);
                    setBoardName(store.currentBoardData.label);
                    setProjectId(store.currentProjectId);
                    // setIsProxyBoard(store.dashBoards.find((b) => b.id === Number(boardId))!.isProxyBoard)


                    await store
                        .getProjectTeamDisplayData({
                            skip: 0,
                            take: 500,
                            filters: {
                                projectId: 1,
                                name: undefined,
                                role: undefined,
                            },
                        })
                        .then((res) => {
                            if (!res?.team || !(res?.team?.length > 0)) {
                                return;
                            }
                            setRelatedUsers(res.team);
                        });

                    const taskFilters = await store.getProjectTasksFilters(store.currentProjectId);

                    if (
                        taskFilters &&
                        taskFilters.type &&
                        taskFilters.priority &&
                        taskFilters.status &&
                        taskFilters.tag
                    )
                        setTaskFilters(taskFilters);

                    await getFilterData();
                    await store.getProjectDashBoards(store.currentProjectId);
                    setBoardsForProxy(
                        store.dashBoards.filter((b) => b.id !== Number(boardId))
                    );
                } else {
                    showToast("У вас нет доступа к этой доске!")
                    navigate(`/project/${store.currentProjectId}`)
                }
            } catch (error) {
                console.log("Пользователь не имеет доступа на доску")
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProjectId, boardId, rerenderBoard]);

    useEffect(() => {
        const curentDashBoard = store.dashBoards.find((b) => b.id === Number(boardId));
        if (curentDashBoard) setIsProxyBoard(curentDashBoard.isProxyBoard);

    }, [boardId, store.dashBoards]);

    useEffect(() => {
        setBoardName(store.currentBoardData.label);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentBoardData]);

    const getFilterData = async () => {
        const filterParams: IIParametrsFilter = {
            projectId: store.currentProjectId,
            date: true,
            responsible: true,
            priority: true,
            typeTask: true,
            status: false,
            role: false,
            fileType: false,
        };
        let data = await store.getDataForFilter(filterParams);
        //Преобразования Имя пользователя к формату ФИО
        let a: any = data?.map(i => {
            if (i.type && i.type === 'users') {
                let c: any = i.items?.map((x: any) => {
                    return { code: x.code, name: initials(x.name!) }
                });
                if (c?.length) {
                    i.items = c;
                    return i;
                }
            } else {
                return i;
            }
        })
        // -------------------------------------------------
        setBoardFilters(a);
        setBoardResetFilters(structuredClone(a));
    }

    const resetFilter = () => {
        setTaskNameSearch('');
        setDatesFilter([]);
        setUsersFilter([]);
        setCheckedBoardFilters({
            priority: undefined,
            typeTask: undefined,
        });
        setBoardFilters(structuredClone(boardResetFilters));
        setSelectedFastFilters({
            priority: undefined,
            type: undefined,
            tag: undefined,
        });
    }

    const filterColumns = () => {
        const filterTasks = (
            tasks: IBoardTaskDisplay[]
        ): IBoardTaskDisplay[] => {
            let filteredTasks = tasks;
            if (taskNameSearch !== "" && taskNameSearch !== undefined) {
                const lowerCaseSearchText = taskNameSearch.toLowerCase();
                filteredTasks = filteredTasks.filter((t) =>
                    t.name.toLowerCase().includes(lowerCaseSearchText)
                );
            }
            if (selectedFastFilters.priority !== undefined) {
                filteredTasks = filteredTasks.filter(
                    (t) => t.priority.id === selectedFastFilters.priority!.id
                );
            }
            if (selectedFastFilters.type !== undefined) {
                filteredTasks = filteredTasks.filter(
                    (t) => t.type.id === selectedFastFilters.type!.id
                );
            }
            if (selectedFastFilters.tag !== undefined) {
                filteredTasks = filteredTasks.filter(
                    (t) => t.tags.some((tag) => tag.id === selectedFastFilters.tag!.id)
                );
            }
            if (
                checkedBoardFilters.priority &&
                checkedBoardFilters.priority.length > 0
            ) {
                filteredTasks = filteredTasks.filter(
                    (t) =>
                        (checkedBoardFilters.priority as number[]).find(
                            (priorityId) => priorityId == t.priority.id
                        ) !== undefined
                );
            }
            if (
                checkedBoardFilters.typeTask &&
                checkedBoardFilters.typeTask.length > 0
            ) {
                filteredTasks = filteredTasks.filter(
                    (t) =>
                        (checkedBoardFilters.typeTask as number[]).find(
                            (typeId) => typeId == t.type.id
                        ) !== undefined
                );
            }
            if (usersFilter && usersFilter.length > 0) {
                filteredTasks = filteredTasks.filter(
                    (t) =>
                        (usersFilter as IFilterUser[]).find(
                            (f) => Number(f.code) === t.responsibleId
                        ) !== undefined
                );
            }
            if (datesFilter && datesFilter[0]) {
                filteredTasks = filteredTasks.filter((t) => {
                    const startDate = new Date(t.startDate);
                    return startDate.getTime() >= datesFilter[0].getTime();
                });
            }
            if (datesFilter && datesFilter[1]) {
                filteredTasks = filteredTasks.filter((t) => {
                    const startDate = new Date(t.startDate);
                    return startDate.getTime() <= datesFilter[1].getTime();
                });
            }
            return filteredTasks;
        };

        // Нет возможности использовать structuredClone
        const columnsCopy: IColumnDisplay[] = JSON.parse(
            JSON.stringify(columns)
        );

        setFilteredColumns(
            columnsCopy.map((c) => {
                c.tasks = filterTasks(c.tasks);
                return c;
            })
        );
    };

    useEffect(() => {
        filterColumns();
    }, [selectedFastFilters, columns]);

    useEffect(() => {
        filterColumns();
    }, [taskNameSearch, columns]);

    useEffect(() => {
        filterColumns();
    }, [datesFilter, columns]);

    useEffect(() => {
        filterColumns();
    }, [usersFilter, columns]);

    useEffect(() => {
        if (!forceFilterChange) {
            if (
                checkedBoardFilters.priority &&
                checkedBoardFilters.priority.length > 0 &&
                checkedBoardFilters.typeTask &&
                checkedBoardFilters.typeTask.length > 0
            ) {
                setSelectedFastFilters({
                    priority: undefined,
                    type: undefined,
                    tag: undefined
                });
            } else if (
                checkedBoardFilters.priority &&
                checkedBoardFilters.priority.length > 0
            ) {
                setSelectedFastFilters({
                    priority: undefined,
                    type: selectedFastFilters.type,
                    tag: undefined
                });
            } else if (
                checkedBoardFilters.typeTask &&
                checkedBoardFilters.typeTask.length > 0
            ) {
                setSelectedFastFilters({
                    priority: selectedFastFilters.priority,
                    type: undefined,
                    tag: undefined
                });
            }
        }
        setForceFilterChange(false);
        filterColumns();
    }, [checkedBoardFilters, columns]);

    const changeFastFilters = (fastFilters: ISelectedFastFilters) => {
        setSelectedFastFilters(fastFilters);

        const boardFiltersCopy: IFilter[] = JSON.parse(
            JSON.stringify(boardFilters)
        );

        boardFiltersCopy.forEach((f) => {
            if (f.nameForFilter === "priority") {
                (f.items as IFilterItem[])?.forEach((i) => {
                    i.checked = false;
                    if (i.id == fastFilters.priority?.id) i.checked = true;
                });
            }
            if (f.nameForFilter === "typeTask") {
                (f.items as IFilterItem[])?.forEach((i) => {
                    i.checked = false;
                    if (i.id == fastFilters.type?.id) i.checked = true;
                });
            }
        });
        let checkedFiltersCopy = JSON.parse(
            JSON.stringify(checkedBoardFilters)
        );

        checkedFiltersCopy.priority = fastFilters.priority
            ? [fastFilters.priority.id]
            : undefined;
        checkedFiltersCopy.typeTask = fastFilters.type
            ? [fastFilters.type.id]
            : undefined;

        setCheckedBoardFilters(checkedFiltersCopy);
        setBoardFilters(boardFiltersCopy);
        setForceFilterChange(true);
    };

    const handleMoveTask = async (
        taskId: number,
        newColumnId: number,
        position: number
    ) => {
        const oldColumn = columns.find(
            (c) => c.tasks.find((t) => t.id === taskId) !== undefined
        );
        const newColumn = columns.find((c) => c.id === newColumnId);

        if (!oldColumn || !newColumn || position < 0) return;

        const task = oldColumn.tasks.find((t) => t.id === taskId);

        if (!task) return;

        try {
            await store.moveTask({
                taskId: task.id,
                columnId: oldColumn === newColumn ? oldColumn.id : newColumn.id,
                boardId: Number(boardId),
                position: position,
            });
            await store.getAllColumnsBoard(Number(boardId));
            setColumns(store.columns);
        } catch (err) {
        }
    };

    const handleMoveTaskBoard = async (taskId: number, toBoardId: number) => {
        try {
            await store.moveTaskBoard({
                taskId: taskId,
                boardId: Number(toBoardId),
            });
            await store.getAllColumnsBoard(Number(boardId));
            setColumns(store.columns);
        } catch (err) {
        }
    }

    const handleMoveTasks = async (columnId: number) => {
        try {
            const moveTasksData: IMoveTasksData = {
                columnId: columnId,
                boardId: Number(boardId),
                tasksId: selectedTasks,
            };
            await store.moveTasks(moveTasksData);
            await store.getAllColumnsBoard(Number(boardId));
            setColumns(store.columns);
            setSelectedTasks([]);
            setActionMode(false);
        } catch (err) {
        }
    };

    const cancelActionMode = () => {
        setActionMode(!actionMode);
        setSelectedTasks([]);
    };

    const handleSelectTask = (taskId: number) => {
        if (selectedTasks.some((xx) => xx === taskId)) {
            setSelectedTasks(selectedTasks.filter((xx) => xx !== taskId));
        } else {
            let tasks = [...selectedTasks];
            tasks.push(taskId);
            setSelectedTasks(tasks);
        }
    };

    const handleDeleteTask = async (taskId: number) => {
        await store.deleteTasks({ tasks: [taskId] });
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
        showToast("Задача удалена");
    };

    const handleDeleteTasks = async (taskIds: number[]) => {
        setTasksToDelete(taskIds);
        setShowConfirmDeleteTasks(true);
    };

    const deleteTasks = async () => {
        await store.deleteTasks({ tasks: tasksToDelete });
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
        setShowConfirmDeleteTasks(false);
        setSelectedTasks([]);
        showToast("Задачи удалены");
    };

    const openMenu = () => {
        setShowMenu(true);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    const handleMoveColumn = async (columnId: number, position: number) => {
        const moveColumnData: IMoveColumn = {
            boardId: Number(boardId),
            columnId: columnId,
            position: position,
        };
        await store.moveColumn(moveColumnData);
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
    };

    const handleDeleteColumn = async (columnId: number) => {
        const column = columns.find(c => c.id === columnId);
        if (!column)
            return;
        setShowMenu(false);
        setColumnToDelete(column);
        setShowConfirmDeleteColumn(true);
    };

    const deleteColumn = async () => {
        if (!columnToDelete)
            return;
        await store.deleteColumn({
            columnId: columnToDelete.id,
            boardId: Number(boardId),
        });
        await store.getAllColumnsBoard(Number(boardId));
        setShowConfirmDeleteColumn(false);
        setColumns(store.columns);
        showToast("Столбец удален");
    }

    const handleEditColumn = async (
        columnId: number,
        newName?: string,
        newColor?: string,
        newWeight?: number
    ) => {
        const editColumnData: IColumnEdit = {
            columnId: columnId,
            boardId: Number(boardId),
            name: newName,
            color: newColor,
            weight: newWeight,
        };

        await store.editColumn(editColumnData);
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
        showToast("Изменения сохранены");
    };

    const openAddTaskForm = (columnId: number) => {
        setAddTaskColumnId(columnId);
        setShowTaskPopup(true);
    };

    const handleRenameBoard = async (newName: string) => {
        await store.editBoard({ id: Number(boardId), name: newName });
        showToast("Изменения сохранены");
        getData();
    };

    const handleDeleteBoard = () => {
        setShowMenu(false);
        setShowConfirmDeleteBoard(true);
    };

    const deleteBoard = async () => {
        await store.deleteBoard({ boardId: Number(boardId) });
        setShowConfirmDeleteBoard(false);
        await getData();
        navigate(`/project/${store.currentProjectId}`);
        showToast("Канбан доска удалена");
    };

    const handleAddColumn = async (
        name: string,
        color: string,
        weight: number,
        columnStatus: number | undefined,
        columnPriority: number | undefined,
        proxyBoardName?: string,
        proxyBoardId?: number
    ) => {
        const addColumnData: IAddColumn = {
            boardId: Number(boardId),
            name: name,
            color: color,
            weight: weight,
            statusId: columnStatus,
            priorityId: columnPriority,
            proxyBoardName: proxyBoardName,
            proxyBoardId: proxyBoardId,
        };
        await store.addColumn(addColumnData);
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
        setRerenderBoard(rerenderBoard ? false : true);
        setShowMenu(false);
        showToast("Столбец создан");
    };

    const handleMakeColumnCommon = async (columnId: number) => {
        const makeColumnCommonData: IMakeColumnCommon = {
            columnId: columnId,
            boardId: Number(boardId),
        };
        await store.makeColumnCommon(makeColumnCommonData);
        await getData();
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
    };

    const handleMakeColumnProxy = async (
        columnId: number,
        proxyBoardName?: string,
        proxyBoardId?: number
    ) => {
        const makeColumnProxyData: IMakeColumnProxy = {
            columnId: columnId,
            boardId: Number(boardId),
            proxyBoardId: proxyBoardId,
            proxyBoardName: proxyBoardName,
        };
        await store.makeColumnProxy(makeColumnProxyData);
        await getData();
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
    };

    const handleTaskAction = async () => {
        await store.getAllColumnsBoard(Number(boardId));
        setColumns(store.columns);
        setShowTaskPopup(false);
    }
    return (
        <BoardContext.Provider
            value={{
                boardIdNumber,
                handleDeleteTask,
                handleDeleteTasks,
                handleSelectTask,
                handleMoveTask,
                handleMoveTaskBoard,
                handleMoveTasks,
                handleMoveColumn,
                columns,
                filteredColumns,
                taskNameSearch,
                setTaskNameSearch,
                taskFilters,
                selectedFastFilters,
                changeFastFilters,
                actionMode,
                cancelActionMode,
                selectedTasks,
                relatedUsers,
                openMenu,
                closeMenu,
                boardName,
                handleDeleteColumn,
                handleEditColumn,
                openAddTaskForm,
                handleRenameBoard,
                handleDeleteBoard,
                handleAddColumn,
                boardsForProxy,
                handleMakeColumnCommon,
                handleMakeColumnProxy,
                colorOptions,
                boardFilters,
                setBoardFilters,
                checkedBoardFilters,
                setCheckedBoardFilters,
                datesFilter,
                setDatesFilter,
                usersFilter,
                setUsersFilter,
                filterCounter,
                setFilterCounter,
                handleTaskAction,
                resetFilter,
                projectId,
                isProxyBoard,
                abbreviationProject
            }}
        >
            {showTaskPopup ? (
                <div className="full-screen-overlay">
                    <div className="popup-form-background" onClick={(e) => e.stopPropagation()}>
                        <TaskAddEditForm
                            mode={"create"}
                            taskId={undefined}
                            closeFunc={() => setShowTaskPopup(false)}
                            columnId={addTaskColumnId!}
                            onTaskAction={handleTaskAction}
                        />
                    </div>
                </div>
            ) : null}
            {showConfirmDeleteTasks ? (
                <ConfirmationWindow
                    headerText="Удалить задачи?"
                    bodyText={`${selectedTasks.length} задачи`}
                    confirmText="Удалить"
                    cancelText="Отмена"
                    onConfirm={deleteTasks}
                    onCancel={() => setShowConfirmDeleteTasks(false)}
                />
            ) : null}
            {showConfirmDeleteBoard ? (
                <ConfirmationWindow
                    headerText="Удаление доски"
                    bodyText={`Вы уверены?`}
                    confirmText="Удалить"
                    cancelText="Отмена"
                    onConfirm={deleteBoard}
                    onCancel={() => setShowConfirmDeleteBoard(false)}
                />
            ) : null}
            {showConfirmDeleteColumn ? (
                <ConfirmationWindow
                    headerText="Удалить столбец?"
                    bodyText={`Все задачи в столбце будут удалены`}
                    confirmText="Удалить"
                    cancelText="Отмена"
                    onConfirm={deleteColumn}
                    onCancel={() => setShowConfirmDeleteColumn(false)}
                />
            ) : null}
            <div className="board__container">
                <div className="toolbar-container">
                    <BoardToolbar onMassActionsFunc={cancelActionMode} selectedCount={selectedTasks.length}
                        userAccess={userAccess} />
                </div>
                <div className="board__container--columns">
                    <BoardColumns userAccess={userAccess} />
                </div>
            </div>
            <div>
                {showMenu ? <BoardMenu userAccess={userAccess} /> : null}
            </div>
        </BoardContext.Provider>
    );
};
export default observer(Board);
